import { useDashboard, useStakingV1 } from "src/contexts"
import { decodeTxErrorMessage, formatEther_Optimized, formatFixedNumber_Optimized } from "src/utils"
import { LoadingButton } from "@mui/lab"
import { useWeb3React } from "@web3-react/core"
import { useState } from "react"
import { BigNumber } from "@ethersproject/bignumber"
import { useSnackbar } from "src/hooks/useSnackbar"


export const EmergencyUnstakeLIQRLP = () => {
    const { LIQRLPInfo, blockTimestamp, updateLIQRLPStakedStats } = useDashboard()
    const { userLIQRLPStakedStats, updateUserLIQRStakedStats, updateUserLIQRLPStakedStats, emergencyWithdrawLIQRLPStaked } = useStakingV1()
    const { account } = useWeb3React()
    const [isEarlyUnstaking, setIsEarlyUnstaking] = useState(false)
    const snackbar = useSnackbar()

    const onEarlyUnstake = async () => {
        setIsEarlyUnstaking(true)
        try {
            await emergencyWithdrawLIQRLPStaked().then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Early unstaked successfully!", "success")
                    updateUserLIQRStakedStats()
                    updateUserLIQRLPStakedStats()
                    updateLIQRLPStakedStats()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsEarlyUnstaking(false)
    }

    return (
        <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full max-w-[768px] flex flex-col justify-center items-center gap-6 my-8">
                <div className="w-full flex flex-col items-center">
                    <div className="text-[18px] lg:text-[20px] text-app-primary font-medium">
                        {`${LIQRLPInfo ? LIQRLPInfo.symbol : "LIQR-LP"} Staking Pool`}
                    </div>
                </div>
                <div className="w-full bg-app-content box-border rounded-md p-6 flex justify-center">
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Your Unstaked ${LIQRLPInfo ? LIQRLPInfo.symbol : "LIQR-LP"}: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRLPStakedStats ? formatEther_Optimized(userLIQRLPStakedStats.unstakedAmount, LIQRLPInfo.decimals, 3, true) : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Your Staked ${LIQRLPInfo ? LIQRLPInfo.symbol : "LIQR-LP"}: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRLPStakedStats ? formatEther_Optimized(userLIQRLPStakedStats.stakedAmount, LIQRLPInfo.decimals, 3, true) : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Days Staked: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRLPStakedStats ? userLIQRLPStakedStats.lastStakedTimestamp > 0 ? formatFixedNumber_Optimized((blockTimestamp - userLIQRLPStakedStats.lastStakedTimestamp) / 86400, 2, false) : 0 : "--"}</div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex items-stretch gap-4">
                    <LoadingButton
                        variant="outlined"
                        sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                        loading={isEarlyUnstaking}
                        loadingPosition="start"
                        onClick={() => onEarlyUnstake()}
                        disabled={!account || (userLIQRLPStakedStats ? userLIQRLPStakedStats.stakedAmount.lte(0) : true)}
                    >
                        <span className="text-[16px] leading-[1.1] text-center">{isEarlyUnstaking ? 'Unstaking ...' : 'Unstake V1'}</span>
                    </LoadingButton>
                </div>
            </div>
        </div>
    )
}