
export default function TitleAndLogo() {
    return (
        <div className="flex items-center gap-4">
            <a href="https://liquidityrush.com/">
                <div className='w-full max-w-[48px]'>
                    <img src="/lqr_icon.png" alt="" width="100%"/>
                </div>
            </a>
        </div>
    )
}