import { calculateGasMargin, getContract } from 'src/utils'
import { BigNumber, Contract } from 'ethers'
import React, { useContext, useEffect, useState } from 'react'
import REFERRALSYSTEM_ABI from 'src/constants/contracts/abis/referralSystem.json'
import { TransactionResponse, Web3Provider } from '@ethersproject/providers'
import { LIQRStakingV2_CAs, ReferralSystem_CAs, RpcProviders, Wrapped_Ethers } from 'src/constants/AppConstants'
import useRefresh from 'src/hooks/useRefresh'
import { useWeb3React } from '@web3-react/core'
import { useLIQR } from './LIQRContext'
import { formatUnits } from '@ethersproject/units'
import useMemoizedState from 'src/hooks/useMemorizedState'
import { useDashboard } from './DashboardContext'
import LIQR_TOKEN_ABI from 'src/constants/contracts/abis/liqrToken.json'
import STAKE_ABI from 'src/constants/contracts/abis/stake.json'

declare type Maybe<T> = T | null | undefined

export interface IReferralInfo {
    directLifetimeReferrals: number
    indirectLifetimeReferrals: number
    directLifetimeETHValueReferred: BigNumber
    indirectLifetimeETHValueReferred: BigNumber
    directLast7DaysReferrals: number
    indirectLast7DaysReferrals: number
    directLast7DaysETHValueReferred: BigNumber
    indirectLast7DaysETHValueReferred: BigNumber
    valueOfLast7DaysETHValueReferred: number

    directLifetimeReferralBonus: BigNumber
    valueOfDirectLifetimeReferralBonus: number
    indirectLifetimeReferralBonus: BigNumber
    valueOfIndirectLifetimeReferralBonus: number

    directLast7DaysReferralBonus: BigNumber
    indirectLast7DaysReferralBonus: BigNumber

    unclaimedReferralBonus: BigNumber
    valueOfUnclaimedReferralBonus: number

    referralBonusDebt: BigNumber
}

export interface IReferrerInfo {
    referralCode: string
    referrer: string
}

export interface IUserTierInfo {
    tier: number
    multiplier: number
}

export interface IReferralSystemContext {
    referralFee: number
    totalFee: number
    buyFeeOnUniswap: number
    referrerInfo: IReferrerInfo
    yourReferralCode: string
    userReferralInfos: IReferralInfo
    userTierInfo: IUserTierInfo
    tierReferralsThreshold: number[] | undefined
    tierETHValueThreshold: BigNumber[] | undefined
    updateFeeInfos: () => Promise<any>
    updateYourReferralCode: () => Promise<any>
    updateUserReferralInfos: () => Promise<any>
    claimBonusAsETH: () => Promise<any>
    swapClaimableETHforTokens: () => Promise<any>
    estimatedBuyWithReferral: (amount: BigNumber, slip: number) => Promise<any>
    buyWithReferral: (amount: BigNumber, slip: number) => Promise<any>
    createReferralCode: (code: string) => Promise<any>
    setReferrerInfo: (v: IReferrerInfo) => void
    getAmountOut: (amount: BigNumber) => Promise<any>
    getSwapAmountOutMin: (amountOut: BigNumber, slip: number) => BigNumber
}

const ReferralSystemContext = React.createContext<Maybe<IReferralSystemContext>>(null)

export const ReferralSystemProvider = ({ children = null as any }) => {
    const { account, provider } = useWeb3React()
    const { slowRefresh } = useRefresh()
    const { selectedChainId } = useLIQR()
    const { ethPriceInUSD, LIQRInfo } = useDashboard()
    const [referralFee, setReferralFee] = useState(0)
    const [totalFee, setTotalFee] = useState(0)
    const [yourReferralCode, setYourReferralCode] = useState('')
    const [referrerInfo, setReferrerInfo] = useMemoizedState<IReferrerInfo>(undefined)
    const [userReferralInfos, setUserReferralInfos] = useMemoizedState<IReferralInfo>(undefined)
    const [buyFeeOnUniswap, setBuyFeeOnUniswap] = useState(0)
    const [userTierInfo, setUserTierInfo] = useState<IUserTierInfo>(undefined)
    const [tierReferralsThreshold, setTierReferralsThreshold] = useState<number[]>()
    const [tierETHValueThreshold, setTierETHValueThreshold] = useState<BigNumber[]>()

    const init = () => {
        setYourReferralCode('')
        setUserReferralInfos(undefined)
        setUserTierInfo(undefined)
        setTierReferralsThreshold(undefined)
        setTierETHValueThreshold(undefined)
    }

    const updateFeeInfos = async () => {
        try {
            const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, RpcProviders[selectedChainId], undefined)
            let res = await referralSystemContract.feeInfo()
            setReferralFee(Number(res.referralFee) / 100)
            setTotalFee(Number(res.totalFee) / 100)
        } catch (e) { }
    }

    const updateYourReferralCode = async () => {
        try {
            const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, RpcProviders[selectedChainId], undefined)
            let res = await referralSystemContract.referralCodeByUser(account)
            setYourReferralCode(res)
        } catch (e) { }
    }

    const updateBuyFeeOnUniswap = async () => {
        const liqrTokenContract: Contract = getContract(LIQRInfo.address, LIQR_TOKEN_ABI, RpcProviders[selectedChainId], undefined)
        setBuyFeeOnUniswap(Number(await liqrTokenContract.buyTotalTax()) / 100);
    }

    const getTierAndMultiplier = (_tierReferralsThreshold: number[], _tierETHValueThreshold: BigNumber[], referrals: number, ethValue: BigNumber) => {
        let tier = 1;
        let multiplier = 100;
        if (
            referrals >= _tierReferralsThreshold[3] &&
            ethValue.gte(_tierETHValueThreshold[3])
        ) {
            // tier5
            tier = 5;
            multiplier = 200;
        } else if (
            referrals >= _tierReferralsThreshold[2] &&
            ethValue.gte(_tierETHValueThreshold[2])
        ) {
            // tier4
            tier = 4;
            multiplier = 150;
        } else if (
            referrals >= _tierReferralsThreshold[1] &&
            ethValue.gte(_tierETHValueThreshold[1])
        ) {
            // tier3
            tier = 3;
            multiplier = 130;
        } else if (
            referrals >= _tierReferralsThreshold[0] &&
            ethValue.gt(_tierETHValueThreshold[0])
        ) {
            // tier2
            tier = 2;
            multiplier = 120;
        }

        return ({ tier: tier, multiplier: multiplier })
    }

    const updateUserReferralInfos = async () => {
        try {
            const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, RpcProviders[selectedChainId], undefined)
            const res = await referralSystemContract.referralInfo(account)
            const pending = await referralSystemContract.pendingBonus(account)
            const valueOfPending = Number(formatUnits(pending, Wrapped_Ethers[selectedChainId].decimals)) * ethPriceInUSD
            const last7DaysETHValueReferred = res.directLast7DaysETHValueReferred.add(res.indirectLast7DaysETHValueReferred)
            // const valueOfLast7DaysETHValueReferred = Number(formatUnits(last7DaysETHValueReferred, Wrapped_Ethers[selectedChainId].decimals)) * ethPriceInUSD
            const valueOfLast7DaysETHValueReferred = Number(formatUnits(res.directLast7DaysETHValueReferred, Wrapped_Ethers[selectedChainId].decimals)) * ethPriceInUSD
            const valueOfDirectLifetimeReferralBonus = Number(formatUnits(res.directLifetimeReferralBonus, Wrapped_Ethers[selectedChainId].decimals)) * ethPriceInUSD
            const valueOfIndirectLifetimeReferralBonus = Number(formatUnits(res.indirectLifetimeReferralBonus, Wrapped_Ethers[selectedChainId].decimals)) * ethPriceInUSD

            let _tierReferralsThreshold = tierReferralsThreshold
            let _tierETHValueThreshold = tierETHValueThreshold
            if (!_tierReferralsThreshold) {
                const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, RpcProviders[selectedChainId], undefined)
                const tierRes = await liqrStakingContract.getTierThreshold()
                _tierReferralsThreshold = [Number(tierRes[0][0]), Number(tierRes[0][1]), Number(tierRes[0][2]), Number(tierRes[0][3])]
                _tierETHValueThreshold = [tierRes[1][0], tierRes[1][1], tierRes[1][2], tierRes[1][3]]
                setTierReferralsThreshold(_tierReferralsThreshold)
                setTierETHValueThreshold(_tierETHValueThreshold)
            }
            const tier = getTierAndMultiplier(_tierReferralsThreshold, _tierETHValueThreshold, Number(res.directLast7DaysReferrals), res.directLast7DaysETHValueReferred)
            setUserTierInfo({ tier: tier.tier, multiplier: tier.multiplier })

            setUserReferralInfos({
                directLifetimeReferrals: Number(res.directLifetimeReferrals),
                indirectLifetimeReferrals: Number(res.indirectLifetimeReferrals),
                directLifetimeETHValueReferred: res.directLifetimeETHValueReferred,
                indirectLifetimeETHValueReferred: res.indirectLifetimeETHValueReferred,
                directLast7DaysReferrals: Number(res.directLast7DaysReferrals),
                indirectLast7DaysReferrals: Number(res.indirectLast7DaysReferrals),
                directLast7DaysETHValueReferred: res.directLast7DaysETHValueReferred,
                indirectLast7DaysETHValueReferred: res.indirectLast7DaysETHValueReferred,
                valueOfLast7DaysETHValueReferred: valueOfLast7DaysETHValueReferred,

                directLifetimeReferralBonus: res.directLifetimeReferralBonus,
                valueOfDirectLifetimeReferralBonus: valueOfDirectLifetimeReferralBonus,
                indirectLifetimeReferralBonus: res.indirectLifetimeReferralBonus,
                valueOfIndirectLifetimeReferralBonus: valueOfIndirectLifetimeReferralBonus,

                directLast7DaysReferralBonus: res.directLast7DaysReferralBonus,
                indirectLast7DaysReferralBonus: res.indirectLast7DaysReferralBonus,

                unclaimedReferralBonus: pending,
                valueOfUnclaimedReferralBonus: valueOfPending,

                referralBonusDebt: res.referralBonusDebt
            })
        } catch (e) { console.log(e) }
    }

    useEffect(() => {
        updateFeeInfos()
    }, [selectedChainId])

    useEffect(() => {
        if (LIQRInfo) {
            updateBuyFeeOnUniswap()
        }
    }, [LIQRInfo])

    useEffect(() => {
        init()
        updateYourReferralCode()
    }, [selectedChainId, account])

    useEffect(() => {
        if (account) {
            updateUserReferralInfos()
        }
    }, [account, ethPriceInUSD, selectedChainId, slowRefresh])

    const createReferralCode = async function (code: string) {
        if (!account || !provider) return
        const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, provider as Web3Provider, account ? account : undefined)
        return referralSystemContract.estimateGas.createReferralCode(code).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return referralSystemContract.createReferralCode(code, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const claimBonusAsETH = async function () {
        if (!account || !provider) return
        const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, provider as Web3Provider, account ? account : undefined)
        return referralSystemContract.estimateGas.claimBonusAsETH().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return referralSystemContract.claimBonusAsETH({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const swapClaimableETHforTokens = async function () {
        if (!account || !provider) return
        const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, provider as Web3Provider, account ? account : undefined)
        return referralSystemContract.estimateGas.swapClaimableETHforTokens().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return referralSystemContract.swapClaimableETHforTokens({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const getGasUsed = async (estimatedGas: BigNumber, chainId: number) => {
        let gasPrice = await RpcProviders[chainId].getGasPrice()
        let gasUsed = calculateGasMargin(estimatedGas).mul(gasPrice)
        return gasUsed
    }

    const getSwapAmountOutMin = (amountOut: BigNumber, slip: number) => { //slippage is %        
        let slippage = Math.round((100 - slip) * 100)
        if (slippage < 0) slippage = 0
        return amountOut.mul(BigNumber.from(slippage)).div(BigNumber.from(10000))
    }

    const estimatedBuyWithReferral = async (amount: BigNumber, slip: number) => {
        const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, provider as Web3Provider, account ? account : undefined)
        var deadline = Math.floor(Date.now() / 1000) + 900
        const estimatedAmountOut = await getAmountOut(amount)
        const amountOutMin = getSwapAmountOutMin(estimatedAmountOut, slip)
        return referralSystemContract.estimateGas.buyWithReferral(amountOutMin, account, deadline, referrerInfo.referralCode, { value: amount }).then(async estimatedGasLimit => {
            let gasUsed = await getGasUsed(estimatedGasLimit, selectedChainId)
            return gasUsed
        })
    }

    const buyWithReferral = async function (amount: BigNumber, slip: number) {
        if (!account || !provider) return
        var deadline = Math.floor(Date.now() / 1000) + 900
        const estimatedAmountOut = await getAmountOut(amount)
        const amountOutMin = getSwapAmountOutMin(estimatedAmountOut, slip)
        const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, provider as Web3Provider, account ? account : undefined)
        return referralSystemContract.estimateGas.buyWithReferral(amountOutMin, account, deadline, referrerInfo.referralCode, { value: amount }).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return referralSystemContract.buyWithReferral(amountOutMin, account, deadline, referrerInfo.referralCode, { value: amount, gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const getAmountOut = async function (amount: BigNumber) {
        try {
            const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, RpcProviders[selectedChainId], undefined)
            const amountsOut = await referralSystemContract.getAmountsOut(amount)
            return amountsOut[1]
        } catch (err) { }
        return BigNumber.from(0)
    }

    return (
        <ReferralSystemContext.Provider
            value={{
                referralFee,
                totalFee,
                buyFeeOnUniswap,
                yourReferralCode,
                referrerInfo,
                userReferralInfos,
                userTierInfo,
                tierReferralsThreshold,
                tierETHValueThreshold,
                updateFeeInfos,
                updateYourReferralCode,
                updateUserReferralInfos,
                claimBonusAsETH,
                swapClaimableETHforTokens,
                estimatedBuyWithReferral,
                buyWithReferral,
                createReferralCode,
                setReferrerInfo,
                getAmountOut,
                getSwapAmountOutMin
            }}

        >
            {children}
        </ReferralSystemContext.Provider >
    )
}

export const useReferralSystem = () => {
    const context = useContext(ReferralSystemContext)

    if (!context) {
        throw new Error('Component rendered outside the provider tree')
    }

    return context
}

