import { useDashboard, useLIQR } from "src/contexts"
import { decodeTxErrorMessage, formatEther_Optimized, formatFixedNumber_Optimized } from "src/utils"
import { LoadingButton } from "@mui/lab"
import { useWeb3React } from "@web3-react/core"
import { useState } from "react"
import { useSnackbar } from "src/hooks/useSnackbar"
import { Wrapped_Ethers } from "src/constants/AppConstants"
import { useReferralSystem } from "src/contexts/ReferralSystemContext"
import { TierProgressBar } from "./TierProgressBar"
import { CreateCustomLink } from "./CreateCustomLink"

export const ShillToEarn = () => {
    const { LIQRInfo } = useDashboard()
    const { referralFee, totalFee, buyFeeOnUniswap, userReferralInfos, swapClaimableETHforTokens, claimBonusAsETH, updateUserReferralInfos } = useReferralSystem()
    const { userTierInfo } = useReferralSystem()
    const { account } = useWeb3React()
    const snackbar = useSnackbar()
    const [isSwappingForLIQR, setIsSwappingForLIQR] = useState(false)
    const [isClaimingBonus, setIsClaimingBonus] = useState(false)
    const { selectedChainId } = useLIQR()

    const onSwapClaimableETHForLIQR = async () => {
        setIsSwappingForLIQR(true)
        try {
            await swapClaimableETHforTokens().then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Swapped successfully!", "success")
                    updateUserReferralInfos()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsSwappingForLIQR(false)
    }

    const onClaimBonus = async () => {
        setIsClaimingBonus(true)
        try {
            await claimBonusAsETH().then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Claimed successfully!", "success")
                    updateUserReferralInfos()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsClaimingBonus(false)
    }

    return (
        <div className="w-full flex flex-col flex-1 justify-center items-center gap-8">
            <div className="w-full max-w-[768px] flex flex-col justify-center items-center gap-6 my-8">
                <div className="w-full flex flex-col items-center">
                    <div className="text-[18px] lg:text-[20px] text-app-primary font-medium">
                        Shill-to-Earn
                    </div>
                    <div className="text-[14px] text-white text-center">
                        {`Earn ${referralFee > 0 ? referralFee : "--"}% of each buy made using your link and earn when they refer too!`}
                    </div>
                </div>
                {account && userTierInfo && < div className="w-full bg-app-content box-border rounded-md px-6 py-2 text-center">
                    <div className="w-full flex flex-col items-center">
                        <div className="text-[18px] lg:text-[20px] text-white font-medium">Your Tier</div>
                        <div className="text-[16px] text-white font-medium">
                            {`Tier ${userTierInfo ? userTierInfo.tier : "--"} (${userTierInfo ? userTierInfo.multiplier : "--"}% staking rewards)`}
                        </div>
                        <div className="text-[14px] text-white">
                            {`Earn multipliers on staking rewards for actively referring`}
                        </div>
                        <div className="w-full mt-2 max-w-[330px]">
                            <TierProgressBar tier={userTierInfo ? userTierInfo.tier : 1} />
                        </div>
                    </div>
                </div>}
                <div className="w-full bg-app-content box-border rounded-md px-6 py-2 text-center">
                    <div className="w-full text-center text-[14px] text-white">
                        {`Your referral gives users ${totalFee > 0 ? totalFee : "--"}% buy tax instead of ${buyFeeOnUniswap > 0 ? buyFeeOnUniswap : "--"}%.`}
                    </div>
                    <CreateCustomLink />
                </div>
                <div className="w-full bg-app-content box-border rounded-md p-6 flex justify-center">
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Lifetime Referrals: `}
                            </div>
                            <div className="text-white text-[16px]">{userReferralInfos ? userReferralInfos.directLifetimeReferrals : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Last 7 Days Referrals: `}
                            </div>
                            {/* <div className="text-white text-[16px]">{userReferralInfos ? userReferralInfos.directLast7DaysReferrals + userReferralInfos.indirectLast7DaysReferrals : "--"}</div> */}
                            <div className="text-white text-[16px]">{userReferralInfos ? userReferralInfos.directLast7DaysReferrals : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Value Of Referrals (last 7 days): `}
                            </div>
                            <div className="text-white text-[16px]">{userReferralInfos ? `${formatEther_Optimized(userReferralInfos.directLast7DaysETHValueReferred, Wrapped_Ethers[selectedChainId].decimals, 2, true)} ($${formatFixedNumber_Optimized(userReferralInfos.valueOfLast7DaysETHValueReferred, 2, true)})` : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Direct Earnings: `}
                            </div>
                            <div className="text-white text-[16px]">{userReferralInfos ? `${formatEther_Optimized(userReferralInfos.directLifetimeReferralBonus, Wrapped_Ethers[selectedChainId].decimals, 2, true)} ($${formatFixedNumber_Optimized(userReferralInfos.valueOfDirectLifetimeReferralBonus, 2, true)})` : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Indirect Earnings: `}
                            </div>
                            <div className="text-white text-[16px]">{userReferralInfos ? `${formatEther_Optimized(userReferralInfos.indirectLifetimeReferralBonus, Wrapped_Ethers[selectedChainId].decimals, 2, true)} ($${formatFixedNumber_Optimized(userReferralInfos.valueOfIndirectLifetimeReferralBonus, 2, true)})` : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Lifetime Earnings: `}
                            </div>
                            <div className="text-white text-[16px]">{userReferralInfos ? `${formatEther_Optimized(userReferralInfos.directLifetimeReferralBonus.add(userReferralInfos.indirectLifetimeReferralBonus), Wrapped_Ethers[selectedChainId].decimals, 2, true)} ($${formatFixedNumber_Optimized(userReferralInfos.valueOfDirectLifetimeReferralBonus + userReferralInfos.valueOfIndirectLifetimeReferralBonus, 2, true)})` : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Unclaimed Earnings: `}
                            </div>
                            <div className="text-white text-[16px]">{userReferralInfos ? `${formatEther_Optimized(userReferralInfos.unclaimedReferralBonus, Wrapped_Ethers[selectedChainId].decimals, 2, true)} ($${formatFixedNumber_Optimized(userReferralInfos.valueOfUnclaimedReferralBonus, 2, true)})` : "--"}</div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex items-stretch gap-4">
                    <div className="basis-1/2">
                        <LoadingButton
                            variant="contained"
                            sx={{ width: "100%", borderRadius: "9999px", height: '45px' }}
                            loading={isSwappingForLIQR}
                            loadingPosition="start"
                            onClick={onSwapClaimableETHForLIQR}
                            disabled={!account || (userReferralInfos ? userReferralInfos.unclaimedReferralBonus.lte(0) : true)}
                        >
                            <span className="text-[16px] leading-[1.1] text-center">{isSwappingForLIQR ? 'Swapping ...' : `Swap to ${LIQRInfo ? LIQRInfo.symbol : "LIQR"}`}</span>
                        </LoadingButton>
                    </div>
                    <div className="basis-1/2">
                        <LoadingButton
                            variant="outlined"
                            sx={{ width: "100%", borderRadius: "9999px", height: '45px' }}
                            loading={isClaimingBonus}
                            loadingPosition="start"
                            onClick={onClaimBonus}
                            disabled={!account || (userReferralInfos ? userReferralInfos.unclaimedReferralBonus.lte(0) : true)}
                        >
                            <span className="text-[16px] leading-[1.1] text-center">{isClaimingBonus ? 'Withdrawing ...' : 'Withdraw As ETH'}</span>
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </div >
    )
}