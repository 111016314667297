import { useDashboard, useLIQR, useStaking } from "src/contexts"
import { decodeTxErrorMessage, formatEther_Optimized, formatFixedNumber_Optimized, getBigNumberFromInputString } from "src/utils"
import { LoadingButton } from "@mui/lab"
import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { BigNumber } from "@ethersproject/bignumber"
import { useApproveCallback, useTokenAllowance } from "src/hooks/hooks"
import { useSnackbar } from "src/hooks/useSnackbar"
import { LIQRStakingV2_CAs, RewardsPerDayBasedLIQRAmount } from "src/constants/AppConstants"
import TokenQtyInputWithMaxBtn from "src/common/components/TokenQtyInputWithMaxBtn"
import { formatUnits } from "@ethersproject/units"
import { CompoundLIQRLP_Rewards } from "./CompoundLIQRLP_Rewards"
import Button from "@mui/material/Button"
import Modal from "src/common/components/Modal"
import { useTimeCountDown } from "src/hooks/useTimeCountDown"

const AMOUNT_INPUT_ID = "id_stake_amount_input"

export const LIQRStaking = () => {
    const { LIQRInfo, LIQRLPInfo, LIQRStakedStats, blockTimestamp, updateLIQRStakedStats } = useDashboard()
    const { userLIQRStakedStats, payingOutLIQRLP_Rewards_OnCertainAmt, LIQRPenalty, stakeLIQR, updateUserLIQRStakedStats, updateUserLIQRLPStakedStats, requestToUnlock, emergencyWithdraw, unstakeAndClaimRewards } = useStaking()
    const { account } = useWeb3React()
    const [isRequestingToUnlock, setIsRequestingToUnlock] = useState(false)
    const [isEarlyUnstaking, setIsEarlyUnstaking] = useState(false)
    const [isUnstakingAndClaiming, setIsUnstakingAndClaiming] = useState(false)
    const [allowance, setAllowance] = useState<BigNumber>(undefined)
    const { tokenAllowanceCallback } = useTokenAllowance()
    const { approveCallback } = useApproveCallback()
    const [isWalletApproving, setIsWalletApproving] = useState(false)
    const snackbar = useSnackbar()
    const [inputAmount, setInputAmount] = useState<BigNumber>(BigNumber.from(0))
    const { selectedChainId } = useLIQR()
    const [isCheckingAllowance, setIsCheckingAllowance] = useState(false)
    const [isApproved, setIsApproved] = useState(false)
    const [isStaking, setIsStaking] = useState(false)
    const [isOpenUnlockPopup, setIsOpenUnlockPopup] = useState(false)
    const [isOpenEarlyUnstakePopup, setIsOpenEarlyUnstakePopup] = useState(false)
    const [daysDown, hoursDown, minutesDown, secondsDown] = useTimeCountDown(userLIQRStakedStats ? userLIQRStakedStats.unlockTimestamp * 1000 : 0)
    const [unlockBtnText, setUnlockBtnText] = useState('')
    const [isUnlocked, setIsUnlocked] = useState(false)

    const checkAllowance = async (): Promise<boolean> => {
        if (allowance) {
            if (allowance.gt(0)) return allowance.gte(inputAmount) && inputAmount.gt(0)
        }
        try {
            let res = await tokenAllowanceCallback(account, LIQRStakingV2_CAs[selectedChainId], LIQRInfo.address, selectedChainId)
            setAllowance(res)
            return res.gte(inputAmount) && inputAmount.gt(0)
        } catch (error) {
            console.log(error)
            return false
        }
    }

    useEffect(() => {
        let str = ''
        if (daysDown + hoursDown + minutesDown + secondsDown > 0) {
            if (daysDown > 0) {
                str = 'Unlock in ' + daysDown + 'D ' + hoursDown + 'H ' + minutesDown + 'M'
            } else if (hoursDown > 0) {
                str = 'Unlock in ' + hoursDown + 'H ' + minutesDown + 'M ' + secondsDown + 'S'
            } else if (minutesDown > 0) {
                str = 'Unlock in ' + minutesDown + 'M ' + secondsDown + 'S'
            } else {
                str = 'Unlock in ' + secondsDown + 'S'
            }
            setUnlockBtnText(str)
        } else {
            setUnlockBtnText("Unlock Tokens")
        }
    }, [daysDown, hoursDown, minutesDown, secondsDown])

    useEffect(() => {
        if (userLIQRStakedStats) {
            if (userLIQRStakedStats.unlockTimestamp > 0 && userLIQRStakedStats.unlockTimestamp < blockTimestamp) {
                setIsUnlocked(true)
            }
        }
    }, [userLIQRStakedStats, blockTimestamp])

    useEffect(() => {
        setAllowance(undefined)
    }, [account])

    useEffect(() => {
        const fetch = async () => {
            if (account && inputAmount.gt(0)) {
                const approved = await checkAllowance()
                setIsApproved(approved)
            }
        }
        fetch()
    }, [inputAmount, account])

    const handleStakeAmountChange = (val: string) => {
        setInputAmount(getBigNumberFromInputString(val, LIQRInfo.decimals))
    }

    const initInputBox = () => {
        setInputAmount(BigNumber.from(0))
        let element: any = document.getElementById(AMOUNT_INPUT_ID)
        if (element) element.value = ""
    }

    const onMax = () => {
        if (userLIQRStakedStats && LIQRInfo) {
            setInputAmount(userLIQRStakedStats.unstakedAmount)
            let element: any = document.getElementById(AMOUNT_INPUT_ID)
            if (element) element.value = formatUnits(userLIQRStakedStats.unstakedAmount, LIQRInfo.decimals)
        }
    }

    const onApprove = async () => {
        setIsWalletApproving(true)
        let res = await checkAllowance()
        if (!res) {
            try {
                await approveCallback(LIQRStakingV2_CAs[selectedChainId], LIQRInfo.address).then(res => {
                    setAllowance(res.allowance)
                    setIsApproved(true)
                    snackbar.snackbar.show("Approved!", "success")
                }).catch((error: any) => {
                    console.log(error)
                    let err: any = error
                    snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
                })
            } catch (error) {
                console.log(error)
            }
        } else {
            snackbar.snackbar.show("Approved!", "success")
            setIsApproved(true)
        }
        setIsWalletApproving(false)
        return null
    }

    const onStake = async () => {
        setIsStaking(true)
        try {
            await stakeLIQR(inputAmount).then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Staked successfully!", "success")
                    setAllowance(undefined)
                    updateUserLIQRStakedStats()
                    updateLIQRStakedStats()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsStaking(false)
    }

    const onRequestToUnlock = async () => {
        setIsRequestingToUnlock(true)
        try {
            await requestToUnlock(BigNumber.from(0)).then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Requested to unlock successfully!", "success")
                    updateUserLIQRStakedStats()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsRequestingToUnlock(false)
    }

    const onEarlyUnstake = async () => {
        setIsEarlyUnstaking(true)
        try {
            await emergencyWithdraw(BigNumber.from(0)).then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Early unstaked successfully!", "success")
                    updateUserLIQRStakedStats()
                    updateUserLIQRLPStakedStats()
                    updateLIQRStakedStats()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsEarlyUnstaking(false)
    }

    const onUnstakeAndClaimRewards = async () => {
        setIsUnstakingAndClaiming(true)
        try {
            await unstakeAndClaimRewards(BigNumber.from(0)).then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Unstaked tokens and claimed rewards successfully!", "success")
                    updateUserLIQRStakedStats()
                    updateUserLIQRLPStakedStats()
                    updateLIQRStakedStats()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsUnstakingAndClaiming(false)
    }

    return (
        <div className="w-full flex flex-col flex-1 justify-center items-center gap-8">
            <Modal handleClose={() => setIsOpenUnlockPopup(false)} header={"Request to unlock"} isOpen={isOpenUnlockPopup}>
                <div className="text-[16px] text-white text-center leading-[1.8]">During the unlock period you will not continue to accumulate rewards. You tokens and rewards will be withdrawable without penalty after the 7 days.</div>
                <div className="w-full px-4 mt-6">
                    <LoadingButton
                        variant="outlined"
                        sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                        loading={isRequestingToUnlock}
                        loadingPosition="start"
                        onClick={() => onRequestToUnlock()}
                        disabled={!account || (userLIQRStakedStats ? userLIQRStakedStats.stakedAmount.lte(0) : true)}
                    >
                        <span className="text-[16px] leading-[1.1] text-center">{isRequestingToUnlock ? 'Unlocking ...' : 'Request To Unlock'}</span>
                    </LoadingButton>
                </div>
            </Modal>
            <Modal handleClose={() => setIsOpenEarlyUnstakePopup(false)} header={"Early unstake"} isOpen={isOpenEarlyUnstakePopup}>
                <div className="text-[16px] text-white text-center leading-[1.8]">{`By unstaking early your rewards will be forfeited and you will have a ${LIQRPenalty}% early penalty fee. To unstake without penalty you will need to unlock your tokens first.`}</div>
                <div className="w-full px-4 mt-6">
                    <LoadingButton
                        variant="outlined"
                        sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                        loading={isEarlyUnstaking}
                        loadingPosition="start"
                        onClick={() => onEarlyUnstake()}
                        disabled={!account || (userLIQRStakedStats ? userLIQRStakedStats.stakedAmount.lte(0) : true)}
                    >
                        <span className="text-[16px] leading-[1.1] text-center">{isEarlyUnstaking ? 'Unstaking ...' : 'I wish to unstake immediately'}</span>
                    </LoadingButton>
                </div>
            </Modal>
            <div className="w-full max-w-[768px] flex flex-col justify-center items-center gap-6 my-8">
                <div className="w-full flex flex-col items-center">
                    <div className="text-[18px] lg:text-[20px] text-app-primary font-medium">
                        {`${LIQRInfo ? LIQRInfo.symbol : "LIQR"} Staking Pool`}
                    </div>
                    <div className="text-[14px] text-white">
                        {`Stake ${LIQRInfo ? LIQRInfo.symbol : "LIQR"} and earn ${LIQRLPInfo ? LIQRLPInfo.symbol : "LIQR-LP"}`}
                    </div>
                    {LIQRInfo && <div className="text-[14px] text-white text-center">
                        {`Currently paying out $${payingOutLIQRLP_Rewards_OnCertainAmt} per day based on staking ${formatFixedNumber_Optimized(RewardsPerDayBasedLIQRAmount, 0, true)} ${LIQRInfo.symbol}`}
                    </div>}
                </div>
                <div className="w-full bg-app-content box-border rounded-md px-6 py-2 text-center">
                    {`A total of ${LIQRStakedStats && LIQRInfo ? formatEther_Optimized(LIQRStakedStats.totalStaked, LIQRInfo.decimals, 2, true) : "--"} ${LIQRInfo ? LIQRInfo.symbol : "LIQR"} is staked`}
                </div>
                <div className="w-full bg-app-content box-border rounded-md p-6 flex justify-center">
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Your Unstaked ${LIQRInfo ? LIQRInfo.symbol : "LIQR"}: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRStakedStats ? formatEther_Optimized(userLIQRStakedStats.unstakedAmount, LIQRInfo.decimals, 3, true) : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Your Staked ${LIQRInfo ? LIQRInfo.symbol : "LIQR"}: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRStakedStats ? formatEther_Optimized(userLIQRStakedStats.stakedAmount, LIQRInfo.decimals, 3, true) : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Days Staked: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRStakedStats ? userLIQRStakedStats.lastStakedTimestamp > 0 ? formatFixedNumber_Optimized((blockTimestamp - userLIQRStakedStats.lastStakedTimestamp) / 86400, 2, false) : 0 : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Current Rewards: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRStakedStats ? formatEther_Optimized(userLIQRStakedStats.pendingRewards, LIQRLPInfo.decimals, 3, true) : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Current Value Of Rewards: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRStakedStats ? `$${formatFixedNumber_Optimized(userLIQRStakedStats.valueOfPendingRewards, 3, true)}` : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Lifetime Rewards: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRStakedStats ? formatEther_Optimized(userLIQRStakedStats.earnedAmount, LIQRLPInfo.decimals, 3, true) : "--"}</div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-white text-[14px] sm:text-[16px] whitespace-nowrap w-[200px] sm:w-[240px]">
                                {`Value Of Lifetime Rewards: `}
                            </div>
                            <div className="text-white text-[16px]">{userLIQRStakedStats ? `$${formatFixedNumber_Optimized(userLIQRStakedStats.valueOfEarnedAmount, 3, true)}` : "--"}</div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col gap-2">
                    <TokenQtyInputWithMaxBtn onChange={handleStakeAmountChange} title="Amount To Stake" id={AMOUNT_INPUT_ID} readOnly={!LIQRInfo || !account} onMax={onMax} />
                    <div className="w-full flex items-stretch gap-4">
                        <div className="basis-1/2">
                            <LoadingButton
                                variant="contained"
                                sx={{ width: "100%", borderRadius: "9999px", height: '45px' }}
                                loading={isWalletApproving}
                                loadingPosition="start"
                                onClick={onApprove}
                                disabled={isApproved || !allowance || isCheckingAllowance || !account || inputAmount.lte(0) || (userLIQRStakedStats ? userLIQRStakedStats.unstakedAmount.lt(inputAmount) : true)}
                            >
                                <span className="text-[16px] leading-[1.1] text-center">{isWalletApproving ? 'Approving ...' : isApproved ? "Approved" : "Approve"}</span>
                            </LoadingButton>
                        </div>
                        <div className="basis-1/2">
                            <LoadingButton
                                variant="contained"
                                sx={{ width: "100%", borderRadius: "9999px", height: '45px' }}
                                loading={isStaking}
                                loadingPosition="start"
                                onClick={onStake}
                                disabled={!account || !isApproved || inputAmount.lte(0) || (userLIQRStakedStats ? userLIQRStakedStats.unstakedAmount.lt(inputAmount) : true)}
                            >
                                <span className="text-[16px] leading-[1.1] text-center">{isStaking ? 'Staking ...' : `Stake ${LIQRInfo ? LIQRInfo.symbol : "LIQR"}`}</span>
                            </LoadingButton>
                        </div>
                    </div>
                </div>
                <div className="w-full flex items-stretch gap-4">
                    <div className="basis-1/3">
                        <CompoundLIQRLP_Rewards />
                    </div>
                    <div className="basis-1/3">
                        {!isUnlocked ? <Button
                            variant="outlined"
                            sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                            onClick={() => setIsOpenUnlockPopup(true)}
                            disabled={!account || (userLIQRStakedStats ? userLIQRStakedStats.stakedAmount.lte(0) : true) || (userLIQRStakedStats ? userLIQRStakedStats.unlockTimestamp > 0 : true)}
                        >
                            <span className="text-[16px] leading-[1.1] text-center">{unlockBtnText}</span>
                        </Button>
                            :
                            <LoadingButton
                                variant="outlined"
                                sx={{ width: "100%", borderRadius: "9999px", height: '45px' }}
                                loading={isUnstakingAndClaiming}
                                loadingPosition="start"
                                onClick={onUnstakeAndClaimRewards}
                                disabled={!account || (userLIQRStakedStats ? userLIQRStakedStats.stakedAmount.lte(0) : true)}
                            >
                                <span className="text-[16px] leading-[1.1] text-center">{isUnstakingAndClaiming ? 'Withdrawing ...' : `Withdraw ${LIQRInfo ? LIQRInfo.symbol : "LIQR"} + Rewards`}</span>
                            </LoadingButton>
                        }
                    </div>
                    <div className="basis-1/3">
                        <Button
                            variant="outlined"
                            sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                            onClick={() => setIsOpenEarlyUnstakePopup(true)}
                            disabled={!account || (userLIQRStakedStats ? userLIQRStakedStats.stakedAmount.lte(0) : true) || (userLIQRStakedStats ? userLIQRStakedStats.unlockTimestamp > 0 && userLIQRStakedStats.unlockTimestamp <= blockTimestamp : true)}
                        >
                            <span className="text-[16px] leading-[1.1] text-center">Early Unstake</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div >
    )
}