import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useWeb3React } from "@web3-react/core"
import ConnectButton from "src/common/ConnectButton"
import { useDashboard, useLIQR, useStaking } from "src/contexts"
import { decodeTxErrorMessage, formatEther_Optimized, formatFixedNumber_Optimized } from "src/utils"
import Aos from 'aos'
import 'aos/dist/aos.css'
import LoadingButton from "@mui/lab/LoadingButton"
import { useSnackbar } from "src/hooks/useSnackbar"
import Button from "@mui/material/Button"
import { useApproveCallback, useTokenAllowance } from "src/hooks/hooks"
import { BigNumber } from "@ethersproject/bignumber"
import { RewardsPerDayBasedLIQRAmount, RewardsPerDayBasedLIQRLPAmount, UniswapRouterV2_Addresses } from "src/constants/AppConstants"
import { CompoundLIQRLP_Rewards } from "../liqr-staking/CompoundLIQRLP_Rewards"
import { CompoundLIQR_Rewards } from "../lp-staking/CompoundLIQR_Rewards"
import { TierProgressBar } from "../shill-to-earn/TierProgressBar"
import { CreateCustomLink } from "../shill-to-earn/CreateCustomLink"
import { useReferralSystem } from "src/contexts/ReferralSystemContext"

export const Dashboard = () => {
    const { LIQR_PriceInUSD, LIQRLP_PriceInUSD, LIQRInfo, LIQRLPInfo, LIQRStakedStats, LIQRLPStakedStats, LPProviderStats } = useDashboard()
    const { account } = useWeb3React()
    const [isRedeeming, setIsRedeeming] = useState(false)
    const [isClaimingReflections, setIsClaimingReflections] = useState(false)
    const snackbar = useSnackbar()
    const { selectedChainId } = useLIQR()
    const { userLIQRStakedStats, userLIQRLPStakedStats, payingOutLIQRLP_Rewards_OnCertainAmt, payingOutLIQR_Rewards_OnCertainAmt, updateUserLIQRStakedStats, updateUserLIQRLPStakedStats } = useStaking()
    const { claimableReflections, valueOfClaimableReflections, lifetimeReflections, valueOfLifetimeReflections, updateClaimableReflections, claimReflections, redeem, updateLIQRStakedStats, updateLIQRLPStakedStats, updateLIQR_PriceInUSD, updateLIQRLP_PriceInUSD } = useDashboard()
    const [totalValue, setTotalValue] = useState(0)
    const [isCheckingAllowance, setIsCheckingAllowance] = useState(false)
    const [isApproved, setIsApproved] = useState(false)
    const [allowance, setAllowance] = useState<BigNumber>(undefined)
    const { tokenAllowanceCallback } = useTokenAllowance()
    const { approveCallback } = useApproveCallback()
    const [isWalletApproving, setIsWalletApproving] = useState(false)
    const [expanded, setExpanded] = useState({ reflections: false, LIQR: false, LIQRLP: false })
    const { userTierInfo } = useReferralSystem()

    useEffect(() => {
        Aos.init({ duration: 150 })
    })

    const checkAllowance = async (): Promise<boolean> => {
        if (allowance) {
            if (allowance.gt(0)) return allowance.gte(userLIQRLPStakedStats.unstakedAmount) && userLIQRLPStakedStats.unstakedAmount.gt(0)
        }
        try {
            let res = await tokenAllowanceCallback(account, UniswapRouterV2_Addresses[selectedChainId], LIQRLPInfo.address, selectedChainId)
            setAllowance(res)
            return res.gte(userLIQRLPStakedStats.unstakedAmount) && userLIQRLPStakedStats.unstakedAmount.gt(0)
        } catch (error) {
            console.log(error)
            return false
        }
    }

    useEffect(() => {
        setAllowance(undefined)
    }, [account])

    useEffect(() => {
        const fetch = async () => {
            if (account && userLIQRLPStakedStats) {
                const approved = await checkAllowance()
                setIsApproved(approved)
            }
        }
        fetch()
    }, [userLIQRLPStakedStats, account])

    useEffect(() => {
        if (account && userLIQRStakedStats && userLIQRLPStakedStats) {
            setTotalValue(
                valueOfClaimableReflections +
                userLIQRStakedStats.valueOfStakedAmount +
                userLIQRStakedStats.valueOfUnstakedAmount +
                userLIQRStakedStats.valueOfPendingRewards +
                userLIQRLPStakedStats.valueOfStakedAmount +
                userLIQRLPStakedStats.valueOfUnstakedAmount +
                userLIQRLPStakedStats.valueOfPendingRewards
            )
        }
    }, [userLIQRStakedStats, userLIQRLPStakedStats])

    const onApprove = async () => {
        setIsWalletApproving(true)
        try {
            await approveCallback(UniswapRouterV2_Addresses[selectedChainId], LIQRLPInfo.address).then(res => {
                setAllowance(res.allowance)
                setIsApproved(true)
                snackbar.snackbar.show("Approved!", "success")
            }).catch((error: any) => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsWalletApproving(false)
        return null
    }

    const onRedeem = async () => {
        let res = await checkAllowance()
        if (!res) {
            await onApprove()
        }
        setIsRedeeming(true)
        try {
            await redeem(userLIQRLPStakedStats.unstakedAmount).then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Redeemed successfully!", "success")
                    setAllowance(undefined)
                    updateLIQR_PriceInUSD()
                    updateLIQRLP_PriceInUSD()
                    updateLIQRStakedStats()
                    updateLIQRLPStakedStats()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsRedeeming(false)
    }

    const onClaimReflections = async () => {
        setIsClaimingReflections(true)
        try {
            await claimReflections().then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Claimed your reflections successfully!", "success")
                    updateClaimableReflections()
                    updateUserLIQRStakedStats()
                    updateUserLIQRLPStakedStats()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsClaimingReflections(false)
    }

    return (
        <div className="w-full flex flex-col flex-1 justify-center items-center gap-8 my-8">
            {account && userLIQRStakedStats && userLIQRLPStakedStats && <div className="w-full bg-app-content box-border rounded-md px-6 py-2 text-center">
                <div className="w-full">{`Collectively your total holdings of ${LIQRInfo.symbol}, ${LIQRLPInfo.symbol} and unclaimed rewards is a total value of $${formatFixedNumber_Optimized(totalValue, 2, true)} `}</div>
                {userTierInfo && <div className="w-full mt-2 flex flex-col items-center">
                    <div className="w-full max-w-[330px]">
                        <TierProgressBar tier={userTierInfo ? userTierInfo.tier : 1} />
                    </div>
                    <div className="text-[16px] mt-2 text-white font-medium">
                        {`Tier ${userTierInfo ? userTierInfo.tier : "--"} (${userTierInfo ? userTierInfo.multiplier : "--"}% staking rewards)`}
                    </div>
                    <CreateCustomLink />
                </div>}
            </div>}
            <div className='w-full flex flex-col lg:flex-row gap-10 lg:gap-6 items-stretch my-4'>
                <div
                    data-aos="fade-right"
                    data-aos-easing='linear'
                    className='w-full sm:basis-1/3 flex flex-col flex-1 items-center fit-full-h gap-6'
                >
                    <div className="w-full flex flex-col items-center">
                        <div className='text-[22px] lg:text-[24px] text-white'>{LIQRInfo ? `${LIQRInfo.symbol} Stats` : "-- Stats"}</div>
                        <div className="min-h-[45px] text-[14px] text-white text-center">
                            {LIQRInfo ? `Currently paying out $${payingOutLIQRLP_Rewards_OnCertainAmt} per day based on staking ${formatFixedNumber_Optimized(RewardsPerDayBasedLIQRAmount, 0, true)} ${LIQRInfo.symbol}` : " "}
                        </div>
                        <div className="w-full flex flex-col items-center justify-center rounded-md bg-app-content box-border py-3 gap-4 px-4 xl:px-5 h-[144px] min-h-[144px]">
                            <div className="flex flex-col gap-4">
                                {LIQRInfo && <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[110px]">
                                        {`Price: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQR_PriceInUSD > 0 ? `$${formatFixedNumber_Optimized(LIQR_PriceInUSD, 3, false)}` : "--"}
                                    </div>
                                </div>}
                                {LIQRInfo && <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[110px]">
                                        {`${LIQRInfo.symbol} Staked: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQRStakedStats ? `${formatEther_Optimized(LIQRStakedStats.totalStaked, LIQRInfo.decimals, 2, true)}(${formatFixedNumber_Optimized(LIQRStakedStats.stakedPercent, 2, false)}%)` : "--"}
                                    </div>
                                </div>}
                                {LIQRInfo && <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[110px]">
                                        {`TVL: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQRStakedStats ? `$${formatFixedNumber_Optimized(LIQRStakedStats.TVL, 2, true)}` : "--"}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {account && <div className="w-full flex flex-col items-center gap-4 flex-1 fit-full-h">
                        <div className='w-full flex flex-col flex-1 items-center justify-center rounded-md bg-app-content box-border py-3 gap-[2px] px-4 xl:px-5'>
                            <div className="flex flex-col gap-4">
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[140px]">
                                        {`Unstaked: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {userLIQRStakedStats ? `${formatEther_Optimized(userLIQRStakedStats.unstakedAmount, LIQRInfo.decimals, 3, true)} ${LIQRInfo.symbol} ($${formatFixedNumber_Optimized(userLIQRStakedStats.valueOfUnstakedAmount, 2, true)})` : "--"}
                                    </div>
                                </div>
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[140px]">
                                        {`Staked: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {userLIQRStakedStats ? `${formatEther_Optimized(userLIQRStakedStats.stakedAmount, LIQRInfo.decimals, 3, true)} ${LIQRInfo.symbol} ($${formatFixedNumber_Optimized(userLIQRStakedStats.valueOfStakedAmount, 2, true)})` : "--"}
                                    </div>
                                </div>
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[140px]">
                                        {`Claimable: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {userLIQRStakedStats ? `${formatEther_Optimized(userLIQRStakedStats.pendingRewards, LIQRLPInfo.decimals, 3, true)} ${LIQRLPInfo.symbol} ($${formatFixedNumber_Optimized(userLIQRStakedStats.valueOfPendingRewards, 2, true)})` : "--"}
                                    </div>
                                </div>
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[140px]">
                                        {`Lifetime rewards: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {userLIQRStakedStats ? `${formatEther_Optimized(userLIQRStakedStats.earnedAmount, LIQRLPInfo.decimals, 3, true)} ${LIQRLPInfo.symbol} ($${formatFixedNumber_Optimized(userLIQRStakedStats.valueOfEarnedAmount, 2, true)})` : "--"}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex items-stretch gap-4">
                            <div className="basis-1/2">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    component={Link}
                                    to={"/liqr-staking"}
                                    sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                                >
                                    <span className="text-[16px] leading-[1.1] text-center">{`Go to ${LIQRInfo ? LIQRInfo.symbol : "LIQR"} Staking`}</span>
                                </Button>
                            </div>
                            <div className="basis-1/2">
                                <CompoundLIQRLP_Rewards />
                            </div>
                        </div>
                    </div>}
                </div>
                <div
                    data-aos="fade-down"
                    data-aos-easing='linear'
                    className='w-full sm:basis-1/3 flex flex-col flex-1 items-center fit-full-h gap-6'
                >
                    <div className="w-full flex flex-col items-center">
                        <div className='text-[22px] lg:text-[24px] text-white'>{LIQRLPInfo ? `${LIQRLPInfo.symbol} Stats` : "-- Stats"}</div>
                        <div className="min-h-[45px] text-[14px] text-white text-center">
                            {LIQRLPInfo ? `Currently paying out $${payingOutLIQR_Rewards_OnCertainAmt} per day based on staking ${formatFixedNumber_Optimized(RewardsPerDayBasedLIQRLPAmount, 0, true)} ${LIQRLPInfo.symbol}` : " "}
                        </div>
                        <div className="w-full flex flex-col items-center justify-center rounded-md bg-app-content box-border py-3 gap-4 px-4 xl:px-5 h-[144px] min-h-[144px]">
                            <div className="flex flex-col gap-4">
                                {LIQRLPInfo && <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[130px]">
                                        {`Price: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQRLP_PriceInUSD > 0 ? `$${formatFixedNumber_Optimized(LIQRLP_PriceInUSD, 3, false)}` : "--"}
                                    </div>
                                </div>}
                                {LIQRLPInfo && <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[130px]">
                                        {`${LIQRLPInfo.symbol} Staked: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQRLPStakedStats ? `${formatEther_Optimized(LIQRLPStakedStats.totalStaked, LIQRLPInfo.decimals, 2, true)}(${formatFixedNumber_Optimized(LIQRLPStakedStats.stakedPercent, 2, false)}%)` : "--"}
                                    </div>
                                </div>}
                                {LIQRLPInfo && <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[130px]">
                                        {`TVL: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQRLPStakedStats ? `$${formatFixedNumber_Optimized(LIQRLPStakedStats.TVL, 2, true)}` : "--"}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {account && <div className="w-full flex flex-col items-center gap-4 flex-1 fit-full-h">
                        <div className='w-full flex flex-col flex-1 items-center justify-center rounded-md bg-app-content box-border py-3 gap-[2px] px-4 xl:px-5'>
                            <div className="flex flex-col gap-4">
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[140px]">
                                        {`Unstaked: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {userLIQRLPStakedStats ? `${formatEther_Optimized(userLIQRLPStakedStats.unstakedAmount, LIQRLPInfo.decimals, 3, true)} ${LIQRLPInfo.symbol} ($${formatFixedNumber_Optimized(userLIQRLPStakedStats.valueOfUnstakedAmount, 2, true)})` : "--"}
                                    </div>
                                </div>
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[140px]">
                                        {`Staked: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {userLIQRLPStakedStats ? `${formatEther_Optimized(userLIQRLPStakedStats.stakedAmount, LIQRLPInfo.decimals, 3, true)} ${LIQRLPInfo.symbol} ($${formatFixedNumber_Optimized(userLIQRLPStakedStats.valueOfStakedAmount, 2, true)})` : "--"}
                                    </div>
                                </div>
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[140px]">
                                        {`Claimable: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {userLIQRLPStakedStats ? `${formatEther_Optimized(userLIQRLPStakedStats.pendingRewards, LIQRInfo.decimals, 3, true)} ${LIQRInfo.symbol} ($${formatFixedNumber_Optimized(userLIQRLPStakedStats.valueOfPendingRewards, 2, true)})` : "--"}
                                    </div>
                                </div>
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[140px]">
                                        {`Lifetime rewards: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {userLIQRLPStakedStats ? `${formatEther_Optimized(userLIQRLPStakedStats.earnedAmount, LIQRInfo.decimals, 3, true)} ${LIQRInfo.symbol} ($${formatFixedNumber_Optimized(userLIQRLPStakedStats.valueOfEarnedAmount, 2, true)})` : "--"}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex items-stretch gap-4">
                            <div className="basis-1/2">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    component={Link}
                                    to={"/lp-staking"}
                                    sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                                >
                                    <span className="text-[16px] leading-[1.1] text-center">{`Go to ${LIQRLPInfo ? LIQRLPInfo.symbol : "LIQR-LP"} Staking`}</span>
                                </Button>
                            </div>
                            <div className="basis-1/2">
                                <CompoundLIQR_Rewards />
                            </div>
                        </div>
                    </div>}
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-easing='linear'
                    className='w-full sm:basis-1/3 flex flex-col flex-1 items-center fit-full-h gap-6'
                >
                    <div className="w-full flex flex-col items-center">
                        <div className='text-[22px] lg:text-[24px] text-white'>LP Provider Stats</div>
                        <div className="min-h-[45px] text-[14px] text-white text-center">&nbsp;</div>
                        <div className="w-full flex flex-col items-center justify-center rounded-md bg-app-content box-border py-3 gap-4 px-4 xl:px-5 h-[144px] min-h-[144px]">
                            <div className="flex flex-col gap-4">
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[130px]">
                                        {`LP Value: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQRLPInfo && LPProviderStats ? `$${formatFixedNumber_Optimized(LPProviderStats.value, 2, true)}` : "--"}
                                    </div>
                                </div>
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[130px]">
                                        {`Total Unique LP Holders: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQRLPInfo && LPProviderStats ? `${LPProviderStats.holders}` : "--"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {account && <div className="w-full flex flex-col items-center gap-4 flex-1 fit-full-h">
                        <div className='w-full flex flex-col flex-1 items-center justify-center rounded-md bg-app-content box-border py-3 gap-[2px] px-4 xl:px-5'>
                            <div className="flex flex-col gap-4">
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[90px]">
                                        {`Unclaimed: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQRInfo && LIQRLPInfo ? `${formatEther_Optimized(claimableReflections, LIQRLPInfo.decimals, 3, true)} ${LIQRLPInfo.symbol} ($${formatFixedNumber_Optimized(valueOfClaimableReflections, 2, true)})` : "--"}
                                    </div>
                                </div>
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[90px]">
                                        {`Holding: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {userLIQRLPStakedStats && LIQRLPInfo ? `${formatEther_Optimized(userLIQRLPStakedStats.unstakedAmount, LIQRLPInfo.decimals, 3, true)} ${LIQRLPInfo.symbol} ($${formatFixedNumber_Optimized(userLIQRLPStakedStats.valueOfUnstakedAmount, 2, true)})` : "--"}
                                    </div>
                                </div>
                                <div className="text-white text-[18px] flex items-center gap-4">
                                    <div className="w-[90px]">
                                        {`Lifetime Reflections: `}
                                    </div>
                                    <div className="w-[30px] border-b border-b-[#fff]">

                                    </div>
                                    <div className="text-app-primary">
                                        {LIQRInfo && LIQRLPInfo ? `${formatEther_Optimized(lifetimeReflections, LIQRLPInfo.decimals, 3, true)} ${LIQRLPInfo.symbol} ($${formatFixedNumber_Optimized(valueOfLifetimeReflections, 2, true)})` : "--"}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex items-stretch gap-4">
                            {/* <div className="basis-1/2">
                                <LoadingButton
                                    variant="contained"
                                    sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                                    loading={isRedeeming}
                                    loadingPosition="start"
                                    onClick={() => onRedeem()}
                                    disabled={!account || !LIQRLPInfo || !LIQRInfo || (userLIQRLPStakedStats ? userLIQRLPStakedStats.unstakedAmount.lte(0) : true)}
                                >
                                    <span className="text-[16px] leading-[1.1] text-center">{isWalletApproving ? `Approving ${LIQRLPInfo ? LIQRLPInfo.symbol : "LIQR-LP"} ...` : isRedeeming ? 'Redeeming ...' : `Redeem ${LIQRLPInfo ? LIQRLPInfo.symbol : "LIQR-LP"}`}</span>
                                </LoadingButton>
                            </div>
                            <div className="basis-1/2"> */}
                            <LoadingButton
                                variant="outlined"
                                sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                                loading={isClaimingReflections}
                                loadingPosition="start"
                                onClick={() => onClaimReflections()}
                                disabled={!account || (claimableReflections.lte(0))}
                            >
                                <span className="text-[16px] leading-[1.1] text-center">{isClaimingReflections ? 'Claiming ...' : `Claim Reflections`}</span>
                            </LoadingButton>
                            {/* </div> */}
                        </div>
                    </div>}
                </div>
            </div>
            <div className="w-full border-b border-b-[#505050] my-4">
            </div>
            <div className="w-full flex flex-col md:flex-row items-stretch gap-6">
                <div className="w-full md:basis-1/2 flex flex-col flex-1 fit-full-h gap-4">
                    <div className="w-full py-2 border-b border-[#505050] flex flex-col gap-1">
                        <div className="text-white text-[24px]">
                            {LIQRLPInfo ? `${LIQRLPInfo.symbol} Reflections` : "Reflections"}
                        </div>
                        {LIQRInfo && LIQRLPInfo && expanded.reflections && <div
                            data-aos="fade-down"
                            data-aos-easing='linear'
                            className="text-app-primary text-[16px] my-3">
                            By holding {LIQRInfo.symbol} you automatically receive reflections in {LIQRLPInfo.symbol} which gives you ownership of a portion of the liquidity pool. <br /><br />
                            You can either stake your {LIQRLPInfo.symbol} to earn rewards in {LIQRInfo.symbol} tokens or you can redeem your rewards. <br /><br />
                            When you redeem your {LIQRLPInfo.symbol} tokens you will receive ETH and {LIQRInfo.symbol} tokens.
                        </div>}
                        {!expanded.reflections && <div className="text-[14px] text-app-primary cursor-pointer" onClick={() => setExpanded((v: any) => ({ ...v, reflections: true }))}>
                            {`Learn More >`}
                        </div>}
                        {expanded.reflections && <div className="text-[14px] text-white cursor-pointer" onClick={() => setExpanded((v: any) => ({ ...v, reflections: false }))}>
                            {`< Less`}
                        </div>}
                    </div>
                    <div className="w-full py-2 border-b border-[#505050] flex flex-col gap-1">
                        <div className="text-white text-[24px]">
                            {LIQRInfo ? `${LIQRInfo.symbol} Staking` : "-- Staking"}
                        </div>
                        {LIQRInfo && LIQRLPInfo && expanded.LIQR && <div
                            data-aos="fade-down"
                            data-aos-easing='linear'
                            className="text-app-primary text-[16px] my-3">
                            By staking your {LIQRInfo.symbol} tokens you will receive daily rewards in {LIQRLPInfo.symbol}. <br /><br />
                            You can claim your {LIQRLPInfo.symbol} tokens and then stake these tokens. <br /><br />
                            You can either claim just your {LIQRLPInfo.symbol} rewards or also remove your staked {LIQRInfo.symbol}.
                        </div>}
                        {!expanded.LIQR && <div className="text-[14px] text-app-primary cursor-pointer" onClick={() => setExpanded((v: any) => ({ ...v, LIQR: true }))}>
                            {`Learn More >`}
                        </div>}
                        {expanded.LIQR && <div className="text-[14px] text-white cursor-pointer" onClick={() => setExpanded((v: any) => ({ ...v, LIQR: false }))}>
                            {`< Less`}
                        </div>}
                    </div>
                    <div className="w-full py-2 border-b border-[#505050] flex flex-col gap-1">
                        <div className="text-white text-[24px]">
                            {LIQRLPInfo ? `${LIQRLPInfo.symbol} Staking` : "-- Staking"}
                        </div>
                        {LIQRInfo && LIQRLPInfo && expanded.LIQRLP && <div
                            data-aos="fade-down"
                            data-aos-easing='linear'
                            className="text-app-primary text-[16px] my-3">
                            By staking your {LIQRLPInfo.symbol} tokens you will receive daily rewards in {LIQRInfo.symbol}. <br /><br />
                            You can claim your {LIQRInfo.symbol} tokens and then stake these tokens. <br /><br />
                            You can either claim just your {LIQRInfo.symbol} rewards or also remove your staked {LIQRLPInfo.symbol}.
                        </div>}
                        {!expanded.LIQRLP && <div className="text-[14px] text-app-primary cursor-pointer" onClick={() => setExpanded((v: any) => ({ ...v, LIQRLP: true }))}>
                            {`Learn More >`}
                        </div>}
                        {expanded.LIQRLP && <div className="text-[14px] text-white cursor-pointer" onClick={() => setExpanded((v: any) => ({ ...v, LIQRLP: false }))}>
                            {`< Less`}
                        </div>}
                    </div>
                </div>
                <div className="w-full md:basis-1/2 flex flex-col flex-1 fit-full-h items-center">
                    <div className="w-full max-w-[480px] flex flex-col gap-8 p-4 mt-6">
                        <div className="text-white text-[20px] md:text-[24px]">
                            Get Started Now
                        </div>
                        <div className="text-app-primary text-[18px]">
                            We are on a mission to empower holders, unlocking wealth and securing financial freedom
                        </div>
                        {!account && <ConnectButton />}
                    </div>
                </div>
            </div>
        </div>
    )
}