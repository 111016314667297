import Tooltip from "@mui/material/Tooltip"
import { useEffect, useState } from "react"
import { Native_Currencies } from "src/constants/AppConstants"
import { useLIQR } from "src/contexts"
import { useReferralSystem } from "src/contexts/ReferralSystemContext"
import { formatEther_Optimized } from "src/utils"

export const TierProgressBar = ({ tier }: { tier: number }) => {
    const { tierReferralsThreshold, tierETHValueThreshold } = useReferralSystem()
    const [hoverText, setHowerText] = useState<string[]>()
    const { selectedChainId } = useLIQR()

    useEffect(() => {
        if (tierReferralsThreshold && tierETHValueThreshold) {
            let text = ["", "", "", ""]
            for (let i = 0; i < tierReferralsThreshold.length; i++) {
                text[i] = `Unlocks when you have referred over ${tierReferralsThreshold[i]} users and value of referrals exceed ${formatEther_Optimized(tierETHValueThreshold[i], 18, 2, false)} ${Native_Currencies[selectedChainId].symbol} over the last 7 days`
            }
            setHowerText(text)
        }
    }, [tierReferralsThreshold, tierETHValueThreshold])

    return (
        <div className="w-full flex justify-between items-center">
            {
                [1, 2, 3, 4, 5].map((n) => {
                    return (
                        <>
                            {n === 1 || !hoverText ?
                                <div key={n} className={`w-[32px] h-[32px] flex justify-center items-center ${tier === n ? "bg-[#EEE]" : ""}`} style={{ borderRadius: "9999px", borderColor: "#FFFFFF", borderWidth: '1px' }}>
                                    <span className={`text-[16px] font-medium ${tier === n ? "text-[#000]" : "text-white"}`}>{n}</span>
                                </div>
                                :
                                <Tooltip key={n} title={hoverText[n - 2]} placement="bottom" componentsProps={{ tooltip: { sx: { backgroundColor: '#142028', }, } }}>
                                    <div className={`w-[32px] h-[32px] cursor-pointer flex justify-center items-center ${tier === n ? "bg-[#EEE]" : ""}`} style={{ borderRadius: "9999px", borderColor: "#FFFFFF", borderWidth: '1px' }}>
                                        <span className={`text-[16px] font-medium ${tier === n ? "text-[#000]" : "text-white"}`}>{n}</span>
                                    </div>
                                </Tooltip>
                            }
                        </>
                    )
                })
            }
        </div>
    )
}