export enum SidebarItem {
  DASHBOARD,
  SHILL_TO_EARN,
  LIQR_STAKING,
  LP_STAKING,
  ADD_LP,
  EMERGENCY_UNSTAKE
}

export const SIDEBAR_ROUTES: { [key: string]: { type: string, link: string, title: string } } = {
  [SidebarItem.DASHBOARD]: { type: "item", link: "/dashboard", title: "" },
  [SidebarItem.SHILL_TO_EARN]: { type: "item", link: "/shill-to-earn", title: "" },
  [SidebarItem.LIQR_STAKING]: { type: "item", link: "/liqr-staking", title: "" },
  [SidebarItem.LP_STAKING]: { type: "item", link: "/lp-staking", title: "" },
  [SidebarItem.ADD_LP]: { type: "item", link: "/add-lp", title: "" },
  [SidebarItem.EMERGENCY_UNSTAKE]: { type: "item", link: "emergency-unstake", title: "" }
}

export const SIDEBAR_SUBITEMS: { [key: string]: any[] } = {

}

export const SIDEBAR_ITEMS: { [key: string]: string } = {
  [SidebarItem.DASHBOARD]: "Dashboard",
  [SidebarItem.SHILL_TO_EARN]: "Shill To Earn",
  [SidebarItem.LIQR_STAKING]: "LIQR Staking",
  [SidebarItem.LP_STAKING]: "LP Staking",
  [SidebarItem.ADD_LP]: "Add LP",
  [SidebarItem.EMERGENCY_UNSTAKE]: "Unstake V1"
}
