import { StakingV1Provider } from "src/contexts"
import { EmergencyUnstakeLIQR } from "./EmergencyUnstakeLIQR"
import { EmergencyUnstakeLIQRLP } from "./EmergencyUnstakeLIQRLP"

export const EmergencyUnstake = () => {
    return (
        <StakingV1Provider>
            <div className="w-full flex flex-col flex-1 justify-center items-center gap-8">
                <div className="w-full flex flex-col lg:flex-row gap-8">
                    <div className="w-full lg:basis-1/2">
                        <EmergencyUnstakeLIQR />
                    </div>
                    <div className="w-full lg:basis-1/2">
                        <EmergencyUnstakeLIQRLP />
                    </div>
                </div>
            </div >
        </StakingV1Provider>
    )
}