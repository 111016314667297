import { calculateGasMargin, getContract } from 'src/utils'
import { BigNumber, Contract } from 'ethers'
import React, { useContext } from 'react'
import ROUTER_ABI from 'src/constants/contracts/abis/uniswapRouterV2.json'
import { TransactionResponse, Web3Provider } from '@ethersproject/providers'
import { RpcProviders, UniswapRouterV2_Addresses } from 'src/constants/AppConstants'
import { useLIQR } from './LIQRContext'
import { useWeb3React } from '@web3-react/core'

declare type Maybe<T> = T | null | undefined

export interface IAddLPContext {
    estimatedAddLiquidityToUniswap: (addLiquidityETH: BigNumber, tokenCA: string, amountTokenDesired: BigNumber, amountTokenMin: BigNumber, amountETHMin: BigNumber) => Promise<any>
    addLiquidityToUniswap: (addLiquidityETH: BigNumber, tokenCA: string, amountTokenDesired: BigNumber, amountTokenMin: BigNumber, amountETHMin: BigNumber) => Promise<any>
}

const AddLPContext = React.createContext<Maybe<IAddLPContext>>(null)

export const AddLPProvider = ({ children = null as any }) => {
    const { account, provider } = useWeb3React()
    const { selectedChainId } = useLIQR()

    const getGasUsed = async (estimatedGas: BigNumber, chainId: number) => {
        let gasPrice = await RpcProviders[chainId].getGasPrice()
        let gasUsed = calculateGasMargin(estimatedGas).mul(gasPrice)
        return gasUsed
    }

    const estimatedAddLiquidityToUniswap = async (addLiquidityETH: BigNumber, tokenCA: string, amountTokenDesired: BigNumber, amountTokenMin: BigNumber, amountETHMin: BigNumber) => {
        const router: Contract = getContract(UniswapRouterV2_Addresses[selectedChainId], ROUTER_ABI, provider as Web3Provider, account ? account : undefined)
        var deadline = Math.floor(Date.now() / 1000) + 900
        return router.estimateGas.addLiquidityETH(tokenCA, amountTokenDesired, amountTokenMin, amountETHMin, account, deadline, { value: addLiquidityETH }).then(async estimatedGasLimit => {
            let gasUsed = await getGasUsed(estimatedGasLimit, selectedChainId)
            return gasUsed
        })
    }

    const addLiquidityToUniswap = async function (addLiquidityETH: BigNumber, tokenCA: string, amountTokenDesired: BigNumber, amountTokenMin: BigNumber, amountETHMin: BigNumber) {
        if (!account || !provider) return
        const router: Contract = getContract(UniswapRouterV2_Addresses[selectedChainId], ROUTER_ABI, provider as Web3Provider, account ? account : undefined)
        var deadline = Math.floor(Date.now() / 1000) + 900
        return router.estimateGas.addLiquidityETH(tokenCA, amountTokenDesired, amountTokenMin, amountETHMin, account, deadline, { value: addLiquidityETH }).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return router.addLiquidityETH(tokenCA, amountTokenDesired, amountTokenMin, amountETHMin, account, deadline, { value: addLiquidityETH, gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    return (
        <AddLPContext.Provider
            value={{
                estimatedAddLiquidityToUniswap,
                addLiquidityToUniswap
            }}
        >
            {children}
        </AddLPContext.Provider >
    )
}

export const useAddLP = () => {
    const context = useContext(AddLPContext)

    if (!context) {
        throw new Error('Component rendered outside the provider tree')
    }

    return context
}

