import { useDashboard, useStaking } from "src/contexts"
import { decodeTxErrorMessage } from "src/utils"
import { LoadingButton } from "@mui/lab"
import { useWeb3React } from "@web3-react/core"
import { useState } from "react"
import { useSnackbar } from "src/hooks/useSnackbar"

export const CompoundLIQR_Rewards = () => {
    const { updateLIQRLPStakedStats } = useDashboard()
    const { userLIQRStakedStats, userLIQRLPStakedStats, updateUserLIQRStakedStats, updateUserLIQRLPStakedStats, compoundBoth_Rewards, compoundLIQR_Rewards } = useStaking()
    const { account } = useWeb3React()
    const [isCompounding, setIsCompounding] = useState(false)
    const snackbar = useSnackbar()

    const onCompound = async () => {
        setIsCompounding(true)
        let compound = compoundBoth_Rewards
        if (userLIQRStakedStats) {
            if (userLIQRStakedStats.pendingRewards.lte(0)) compound = compoundLIQR_Rewards
        }
        try {
            await compound().then((res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Compounded successfully!", "success")
                    updateUserLIQRStakedStats()
                    updateUserLIQRLPStakedStats()
                    updateLIQRLPStakedStats()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsCompounding(false)
    }

    return (
        <LoadingButton
            variant="contained"
            sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
            loading={isCompounding}
            loadingPosition="start"
            onClick={() => onCompound()}
            disabled={!account || (userLIQRLPStakedStats ? userLIQRLPStakedStats.pendingRewards.lte(0) : true) || (userLIQRLPStakedStats ? userLIQRLPStakedStats.unlockTimestamp > 0 : true)}
        >
            <span className="text-[16px] leading-[1.1] text-center">{isCompounding ? 'Compounding ...' : 'Compound Rewards'}</span>
        </LoadingButton>
    )
}