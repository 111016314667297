import { calculateGasMargin, formatFixedNumber_Optimized, getContract } from 'src/utils'
import React, { useContext, useEffect, useState } from 'react'
import { BigNumber, Contract } from 'ethers'
import STAKEV1_ABI from 'src/constants/contracts/abis/stakeV1.json'
import { TransactionResponse, Web3Provider } from '@ethersproject/providers'
import { LIQRLPStakingV1_CAs, LIQRStakingV1_CAs, RewardsPerDayBasedLIQRAmount, RewardsPerDayBasedLIQRLPAmount, RpcProviders } from 'src/constants/AppConstants'
import useRefresh from 'src/hooks/useRefresh'
import { useWeb3React } from '@web3-react/core'
import { useLIQR } from './LIQRContext'
import { useTokenBalanceCallback } from 'src/hooks/hooks'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { useDashboard } from './DashboardContext'
import useMemoizedState from 'src/hooks/useMemorizedState'

declare type Maybe<T> = T | null | undefined

export interface IUserStakedV1Stats {
    stakedAmount: BigNumber
    valueOfStakedAmount: number
    unstakedAmount: BigNumber
    valueOfUnstakedAmount: number
    pendingRewards: BigNumber
    valueOfPendingRewards: number
    earnedAmount: BigNumber
    valueOfEarnedAmount: number
    unlockTimestamp: number
    lastStakedTimestamp: number
}

export interface IStakingV1Context {
    LIQRLockDuration: number
    LIQRLPLockDuration: number
    userLIQRStakedStats: IUserStakedV1Stats
    userLIQRLPStakedStats: IUserStakedV1Stats
    rewardsPerDayOnStakeLIQR: BigNumber
    rewardsPerDayOnStakeLIQRLP: BigNumber
    updateLockDuration: () => Promise<any>
    updateUserLIQRStakedStats: () => Promise<any>
    updateUserLIQRLPStakedStats: () => Promise<any>
    depositLIQR: (amount: BigNumber) => Promise<any>
    depositLIQRLP: (amount: BigNumber) => Promise<any>
    withdrawLIQRLP: (isOnlyRewardsClaim: boolean) => Promise<any>
    withdrawLIQR: (isOnlyRewardsClaim: boolean) => Promise<any>
    emergencyWithdrawLIQRStaked: () => Promise<any>
    emergencyWithdrawLIQRLPStaked: () => Promise<any>
    getRewardsPerDayBasedOnCertainAmtOfLIQR: () => string
    getRewardsPerDayBasedOnCertainAmtOfLIQRLP: () => string
}

const StakingV1Context = React.createContext<Maybe<IStakingV1Context>>(null)

export const StakingV1Provider = ({ children = null as any }) => {
    const { account, provider } = useWeb3React()
    const { slowRefresh } = useRefresh()
    const { selectedChainId } = useLIQR()
    const [LIQRLockDuration, setLIQRLockDuration] = useState(0)
    const [LIQRLPLockDuration, setLIQRLPLockDuration] = useState(0)
    const [userLIQRStakedStats, setUserLIQRStakedStats] = useMemoizedState<IUserStakedV1Stats>(undefined)
    const [userLIQRLPStakedStats, setUserLIQRLPStakedStats] = useMemoizedState<IUserStakedV1Stats>(undefined)
    const { LIQRInfo, LIQRLPInfo, LIQR_PriceInUSD, LIQRLP_PriceInUSD, LIQRStakedStats, LIQRLPStakedStats } = useDashboard()
    const { tokenBalanceCallback } = useTokenBalanceCallback()
    const [rewardsPerDayOnStakeLIQR, setRewardsPerDayOnStakeLIQR] = useState(BigNumber.from(0))
    const [rewardsPerDayOnStakeLIQRLP, setRewardsPerDayOnStakeLIQRLP] = useState(BigNumber.from(0))

    const initUser = () => {
        setUserLIQRStakedStats(undefined)
        setUserLIQRLPStakedStats(undefined)
    }

    const init = () => {
        setLIQRLockDuration(0)
        setLIQRLPLockDuration(0)
        setRewardsPerDayOnStakeLIQR(BigNumber.from(0))
        setRewardsPerDayOnStakeLIQRLP(BigNumber.from(0))
    }

    const updateLockDuration = async () => {
        try {
            const liqrStakingContract: Contract = getContract(LIQRStakingV1_CAs[selectedChainId], STAKEV1_ABI, RpcProviders[selectedChainId], undefined)
            setLIQRLockDuration(await liqrStakingContract.lockDuration())
            const liqrLPStakingContract: Contract = getContract(LIQRLPStakingV1_CAs[selectedChainId], STAKEV1_ABI, RpcProviders[selectedChainId], undefined)
            setLIQRLPLockDuration(await liqrLPStakingContract.lockDuration())
        } catch (e) { }
    }

    const updateRewardsPerDay = async () => {
        try {
            const liqrStakingContract: Contract = getContract(LIQRStakingV1_CAs[selectedChainId], STAKEV1_ABI, RpcProviders[selectedChainId], undefined)
            setRewardsPerDayOnStakeLIQR(await liqrStakingContract.rewardsPerDay())
            const liqrLPStakingContract: Contract = getContract(LIQRLPStakingV1_CAs[selectedChainId], STAKEV1_ABI, RpcProviders[selectedChainId], undefined)
            setRewardsPerDayOnStakeLIQRLP(await liqrLPStakingContract.rewardsPerDay())
        } catch (e) { }
    }

    const updateUserLIQRStakedStats = async () => {
        try {
            const liqrStakingContract: Contract = getContract(LIQRStakingV1_CAs[selectedChainId], STAKEV1_ABI, RpcProviders[selectedChainId], undefined)
            const res = await liqrStakingContract.userInfo(account)
            const pending = await liqrStakingContract.pendingReward(account)
            const valueOfPending = Number(formatUnits(pending, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            const unstaked = await tokenBalanceCallback(LIQRInfo.address, selectedChainId)
            const unlock = await liqrStakingContract.holderUnlockTime(account)
            const valueOfStakedAmount = Number(formatUnits(res.amount, LIQRInfo.decimals)) * LIQR_PriceInUSD
            const valueOfUnstakedAmount = Number(formatUnits(unstaked, LIQRInfo.decimals)) * LIQR_PriceInUSD
            const earned = res.totalEarned.add(pending)
            const valueOfEarnedAmount = Number(formatUnits(earned, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            setUserLIQRStakedStats({ stakedAmount: res.amount, valueOfStakedAmount: valueOfStakedAmount, unstakedAmount: unstaked, valueOfUnstakedAmount: valueOfUnstakedAmount, pendingRewards: pending, valueOfPendingRewards: valueOfPending, earnedAmount: earned, valueOfEarnedAmount: valueOfEarnedAmount, unlockTimestamp: unlock, lastStakedTimestamp: Number(unlock) - LIQRLockDuration })
        } catch (e) { }
    }

    const updateUserLIQRLPStakedStats = async () => {
        try {
            const liqrLPStakingContract: Contract = getContract(LIQRLPStakingV1_CAs[selectedChainId], STAKEV1_ABI, RpcProviders[selectedChainId], undefined)
            const res = await liqrLPStakingContract.userInfo(account)
            const pending = await liqrLPStakingContract.pendingReward(account)
            const valueOfPending = Number(formatUnits(pending, LIQRInfo.decimals)) * LIQR_PriceInUSD
            const unstaked = await tokenBalanceCallback(LIQRLPInfo.address, selectedChainId)
            const unlock = await liqrLPStakingContract.holderUnlockTime(account)
            const valueOfStakedAmount = Number(formatUnits(res.amount, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            const valueOfUnstakedAmount = Number(formatUnits(unstaked, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            const earned = res.totalEarned.add(pending)
            const valueOfEarnedAmount = Number(formatUnits(earned, LIQRInfo.decimals)) * LIQR_PriceInUSD
            setUserLIQRLPStakedStats({ stakedAmount: res.amount, valueOfStakedAmount: valueOfStakedAmount, unstakedAmount: unstaked, valueOfUnstakedAmount: valueOfUnstakedAmount, pendingRewards: pending, valueOfPendingRewards: valueOfPending, earnedAmount: earned, valueOfEarnedAmount: valueOfEarnedAmount, unlockTimestamp: unlock, lastStakedTimestamp: Number(unlock) - LIQRLPLockDuration })
        } catch (e) { }
    }

    useEffect(() => {
        init()
    }, [selectedChainId])

    useEffect(() => {
        updateLockDuration()
        updateRewardsPerDay()
    }, [selectedChainId, slowRefresh])

    useEffect(() => {
        initUser()
    }, [selectedChainId, account])

    useEffect(() => {
        if (LIQRInfo && LIQRLPInfo && account) {
            updateUserLIQRStakedStats()
        }
    }, [account, LIQRInfo, LIQRLPInfo, LIQR_PriceInUSD, LIQRLP_PriceInUSD, selectedChainId, LIQRLockDuration, slowRefresh])

    useEffect(() => {
        if (LIQRInfo && LIQRLPInfo && account) {
            updateUserLIQRLPStakedStats()
        }
    }, [account, LIQRInfo, LIQRLPInfo, LIQR_PriceInUSD, LIQRLP_PriceInUSD, selectedChainId, LIQRLPLockDuration, slowRefresh])

    const depositLIQR = async function (amount: BigNumber) {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV1_CAs[selectedChainId], STAKEV1_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.deposit(amount).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.deposit(amount, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const depositLIQRLP = async function (amount: BigNumber) {
        if (!account || !provider) return
        const liqrLPStakingContract: Contract = getContract(LIQRLPStakingV1_CAs[selectedChainId], STAKEV1_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrLPStakingContract.estimateGas.deposit(amount).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrLPStakingContract.deposit(amount, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const withdrawLIQRLP = async function (isOnlyRewardsClaim: boolean) {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV1_CAs[selectedChainId], STAKEV1_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.withdraw(isOnlyRewardsClaim).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.withdraw(isOnlyRewardsClaim, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const withdrawLIQR = async function (isOnlyRewardsClaim: boolean) {
        if (!account || !provider) return
        const liqrLPStakingContract: Contract = getContract(LIQRLPStakingV1_CAs[selectedChainId], STAKEV1_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrLPStakingContract.estimateGas.withdraw(isOnlyRewardsClaim).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrLPStakingContract.withdraw(isOnlyRewardsClaim, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const emergencyWithdrawLIQRStaked = async function () {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV1_CAs[selectedChainId], STAKEV1_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.emergencyWithdraw().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.emergencyWithdraw({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const emergencyWithdrawLIQRLPStaked = async function () {
        if (!account || !provider) return
        const liqrLPStakingContract: Contract = getContract(LIQRLPStakingV1_CAs[selectedChainId], STAKEV1_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrLPStakingContract.estimateGas.emergencyWithdraw().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrLPStakingContract.emergencyWithdraw({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const getRewardsPerDayBasedOnCertainAmtOfLIQR = () => {
        try {
            if (LIQRInfo && LIQRLPInfo && LIQRStakedStats) {
                if (LIQRStakedStats.totalStaked.gt(0)) {
                    return formatFixedNumber_Optimized(Number(formatUnits(rewardsPerDayOnStakeLIQR.mul(parseUnits(RewardsPerDayBasedLIQRAmount.toString(), LIQRInfo.decimals)).div(LIQRStakedStats.totalStaked), LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD, 3, false)
                }
            }
        } catch (err) { }
        return "--"
    }

    const getRewardsPerDayBasedOnCertainAmtOfLIQRLP = () => {
        try {
            if (LIQRInfo && LIQRLPInfo && LIQRLPStakedStats) {
                if (LIQRLPStakedStats.totalStaked.gt(0)) {
                    return formatFixedNumber_Optimized(Number(formatUnits(rewardsPerDayOnStakeLIQRLP.mul(parseUnits(RewardsPerDayBasedLIQRLPAmount.toString(), LIQRLPInfo.decimals)).div(LIQRLPStakedStats.totalStaked), LIQRInfo.decimals)) * LIQR_PriceInUSD, 3, false)
                }
            }
        } catch (err) { }
        return "--"
    }

    return (
        <StakingV1Context.Provider
            value={{
                LIQRLockDuration,
                LIQRLPLockDuration,
                userLIQRStakedStats,
                userLIQRLPStakedStats,
                rewardsPerDayOnStakeLIQR,
                rewardsPerDayOnStakeLIQRLP,
                updateLockDuration,
                updateUserLIQRStakedStats,
                updateUserLIQRLPStakedStats,
                depositLIQR,
                depositLIQRLP,
                withdrawLIQRLP,
                withdrawLIQR,
                emergencyWithdrawLIQRStaked,
                emergencyWithdrawLIQRLPStaked,
                getRewardsPerDayBasedOnCertainAmtOfLIQR,
                getRewardsPerDayBasedOnCertainAmtOfLIQRLP
            }}
        >
            {children}
        </StakingV1Context.Provider >
    )
}

export const useStakingV1 = () => {
    const context = useContext(StakingV1Context)

    if (!context) {
        throw new Error('Component rendered outside the provider tree')
    }

    return context
}