import React, { useMemo, useState, useEffect, useRef } from 'react'
import LPQtyInputBox from './LPQtyInputBox'

interface TokenInputProps {
    id: string
    name: string
    decimals: number
    isAvailableMaxBtn: boolean
    balance: string
    logoURI: string
    onOpenSelectModal: () => void
}

export default function LIQRQtyOutput({ id, name, decimals, isAvailableMaxBtn, balance, logoURI, onOpenSelectModal }: TokenInputProps) {
    const [isBorder, setIsBorder] = useState(false)
    const [width, setWidth] = useState(1000)
    const widthRef = useRef<any>()

    const getListSize = () => {
        if (widthRef) {
            const newWidth = widthRef?.current?.clientWidth
            setWidth(newWidth)
        }
    }

    useEffect(() => {
        const newWidth = widthRef?.current?.clientWidth
        setWidth(newWidth)
        window.addEventListener("resize", getListSize)
    })

    const handleFocus = () => {
        setIsBorder(true)
    }

    const handleBlur = () => {
        setIsBorder(false)
    }

    return (
        <div className="w-full flex flex-col rounded-2xl bg-app-content gap-1 py-2 px-[14px] sm:px-5" style={{ border: isBorder ? "1px solid white" : "none" }} ref={widthRef}>
            <div className={`w-full flex items-center justify-end gap-1 text-[12px] font-normal uppercase text-app-primary`}>
                <span>Balance: </span>
                <div className="text-[#FFFFFF]/[.5] text-[16px] rounded-md text-right whitespace-nowrap">
                    <span>{balance}</span>
                </div>
            </div>
            <div className='w-full flex justify-between items-center w-full'>
                <div className='w-full flex gap-2 sm:gap-3 items-center'>
                    <div className='flex min-w-[120px] gap-2 py-2 px-3 bg-[#101215] rounded-xl justify-left items-center hover:bg-[#102936] cursor-pointer' onClick={onOpenSelectModal}>
                        {name && <>
                            <div className="flex items-center justify-center w-6 h-6">
                                <img src={logoURI} width="22" height="22" />
                            </div>
                            <div className='uppercase text-white text-[13px] sm:text-[14px] leading-[1.1] whitespace-nowrap overflow-hidden'>{name}</div>
                            {/* <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L5.5 5L10 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg> */}
                        </>}
                    </div>
                    <LPQtyInputBox
                        handleFocus={handleFocus}
                        handleBlur={handleBlur}
                        decimals={decimals}
                        onChange={() => { }}
                        id={id}
                        readOnly={true}
                    />
                </div>
            </div>
        </div>
    )
}