import { calculateGasMargin, getContract, getETHPriceFromPriceFeed, getTokenPriceInNativeWithETHPair } from 'src/utils'
import { BigNumber, Contract } from 'ethers'
import React, { useContext, useEffect, useState } from 'react'
import PAIR_ABI from 'src/constants/contracts/abis/pair.json'
import ROUTER_ABI from 'src/constants/contracts/abis/uniswapRouterV2.json'
import STAKE_ABI from 'src/constants/contracts/abis/stake.json'
import LIQR_TOKEN_ABI from 'src/constants/contracts/abis/liqrToken.json'
import DIVIDEND_TRACKER_ABI from 'src/constants/contracts/abis/liqrDividendTracker.json'
import { GOPLUS_LABS_API_URL, LIQRStakingV2_CAs, RpcProviders, UniswapRouterV2_Addresses, Wrapped_Ethers } from 'src/constants/AppConstants'
import useRefresh from 'src/hooks/useRefresh'
import { useLIQR } from './LIQRContext'
import { useTokenCallback } from 'src/hooks/hooks'
import { formatEther, formatUnits } from '@ethersproject/units'
import { useWeb3React } from '@web3-react/core'
import { TransactionResponse, Web3Provider } from '@ethersproject/providers'
import useMemoizedState from 'src/hooks/useMemorizedState'

declare type Maybe<T> = T | null | undefined

export interface ITokenInfo {
    address: string
    name: string
    symbol: string
    decimals: number
    totalSupply: BigNumber
    logoURI: string
    chainId: number
}

export interface IStakedStats {
    totalStaked: BigNumber
    totalStakedMultiplied: BigNumber
    stakedPercent: number
    TVL: number
}

export interface ILPProviderStats {
    value: number
    holders: number
}

export interface IDashboardContext {
    LIQR_PriceInUSD: number
    LIQRLP_PriceInUSD: number
    ethPriceInUSD: number
    LIQRInfo: ITokenInfo
    LIQRLPInfo: ITokenInfo
    LIQRStakedStats: IStakedStats
    LIQRLPStakedStats: IStakedStats
    LPProviderStats: ILPProviderStats
    blockTimestamp: number
    ethPooled: BigNumber
    liqrPooled: BigNumber
    claimableReflections: BigNumber
    valueOfClaimableReflections: number
    lifetimeReflections: BigNumber
    valueOfLifetimeReflections: number
    updateLIQRStakedStats: () => Promise<any>
    updateLIQRLPStakedStats: () => Promise<any>
    updateClaimableReflections: () => Promise<any>
    claimReflections: () => Promise<any>
    updateLIQR_PriceInUSD: () => Promise<any>
    updateLIQRLP_PriceInUSD: () => Promise<any>
    redeem: (amount: BigNumber) => Promise<any>
}

const DashboardContext = React.createContext<Maybe<IDashboardContext>>(null)

export const DashboardProvider = ({ children = null as any }) => {
    const { fastRefresh, slowRefresh } = useRefresh()
    const { selectedChainId } = useLIQR()
    const [LIQR_PriceInUSD, setLIQR_PriceInUSD] = useState(0)
    const [LIQRLP_PriceInUSD, setLIQRLP_PriceInUSD] = useState(0)
    const [ethPriceInUSD, setETHPriceInUSD] = useState(0)
    const { tokenCallback } = useTokenCallback()
    const [LIQRInfo, setLIQRInfo] = useMemoizedState<ITokenInfo>(undefined)
    const [LIQRLPInfo, setLIQRLPInfo] = useMemoizedState<ITokenInfo>(undefined)
    const [LIQRStakedStats, setLIQRStakedStats] = useMemoizedState<IStakedStats>(undefined)
    const [LIQRLPStakedStats, setLIQRLPStakedStats] = useMemoizedState<IStakedStats>(undefined)
    const [LPProviderStats, setLPProviderStats] = useMemoizedState<ILPProviderStats>(undefined)
    const [blockTimestamp, setBlockTimestamp] = useState(0)
    const [ethPooled, setETHPooled] = useState(BigNumber.from(0))
    const [liqrPooled, setLiqrPooled] = useState(BigNumber.from(0))
    const { account, provider } = useWeb3React()
    const [claimableReflections, setClaimableReflections] = useState(BigNumber.from(0))
    const [valueOfClaimableReflections, setValueOfClaimableReflections] = useState(0)
    const [lifetimeReflections, setLifetimeReflections] = useState(BigNumber.from(0))
    const [valueOfLifetimeReflections, setValueOfLifetimeReflections] = useState(0)

    const init = () => {
        setLIQR_PriceInUSD(0)
        setLIQRLP_PriceInUSD(0)
        setETHPriceInUSD(0)
        setLIQRInfo(undefined)
        setLIQRLPInfo(undefined)
        setLIQRStakedStats(undefined)
        setLIQRLPStakedStats(undefined)
        setLPProviderStats(undefined)
        setETHPooled(BigNumber.from(0))
        setLiqrPooled(BigNumber.from(0))
    }

    const initUser = () => {
        setClaimableReflections(BigNumber.from(0))
        setValueOfClaimableReflections(0)
        setLifetimeReflections(BigNumber.from(0))
        setValueOfLifetimeReflections(0)
    }

    const updateBlockTimestamp = async () => {
        try {
            let blocknumber = await RpcProviders[selectedChainId].getBlockNumber()
            let blockData = await RpcProviders[selectedChainId].getBlock(blocknumber)
            setBlockTimestamp(Number(blockData.timestamp))
        } catch (err) { }
    }

    const updateTokenInfos = async () => {
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, RpcProviders[selectedChainId], undefined)
        let res = await liqrStakingContract.poolInfo(BigNumber.from(0))
        const liqrCA = res.stakingToken
        const liqrLPCA = res.rewardToken
        res = await tokenCallback(liqrCA, selectedChainId)
        if (res) {
            setLIQRInfo({ address: res.address, name: res.name, symbol: res.symbol, decimals: res.decimals, totalSupply: res.totalSupply, logoURI: '', chainId: selectedChainId })
        }
        const liqrSymbol = res.symbol
        res = await tokenCallback(liqrLPCA, selectedChainId)
        // const liqrLPSymbol = res.symbol
        const liqrLPSymbol = `${liqrSymbol}-LP`
        if (res) {
            setLIQRLPInfo({ address: res.address, name: res.name, symbol: liqrLPSymbol, decimals: res.decimals, totalSupply: res.totalSupply, logoURI: '', chainId: selectedChainId })
        }
    }

    const updateETHPriceInUSD = async () => {
        let res = await getETHPriceFromPriceFeed(selectedChainId)
        setETHPriceInUSD(res)
    }

    const updateLIQR_PriceInUSD = async () => {
        let res = await getTokenPriceInNativeWithETHPair(selectedChainId, LIQRInfo.address, LIQRInfo.decimals, UniswapRouterV2_Addresses[selectedChainId])
        setLIQR_PriceInUSD(res * ethPriceInUSD)
    }

    const updateLIQRLP_PriceInUSD = async () => {
        try {
            const pairContract: Contract = getContract(LIQRLPInfo.address, PAIR_ABI, RpcProviders[selectedChainId], undefined)
            const reserves = await pairContract.getReserves()
            const token0 = await pairContract.token0()
            let ethReserved, tokenReserved
            if (token0.toLowerCase() === Wrapped_Ethers[selectedChainId].address.toLowerCase()) {
                ethReserved = reserves[0]
                tokenReserved = reserves[1]
            } else {
                ethReserved = reserves[1]
                tokenReserved = reserves[0]
            }
            setETHPooled(ethReserved)
            setLiqrPooled(tokenReserved)
            let p = Number(formatEther(ethReserved)) * ethPriceInUSD
            p = p + Number(formatUnits(tokenReserved, LIQRInfo.decimals)) * LIQR_PriceInUSD
            const price = p / Number(formatEther(LIQRLPInfo.totalSupply))
            setLIQRLP_PriceInUSD(price)
        } catch (e) { }
    }

    const updateLIQRStakedStats = async () => {
        try {
            const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, RpcProviders[selectedChainId], undefined)
            const res = await liqrStakingContract.poolInfo(BigNumber.from(0))
            const staked = res.totalStakedAmt
            const percent = Number(formatUnits(staked, LIQRInfo.decimals)) / Number(formatUnits(LIQRInfo.totalSupply, LIQRInfo.decimals)) * 100
            const tvl = Number(formatUnits(staked, LIQRInfo.decimals)) * LIQR_PriceInUSD
            setLIQRStakedStats({ totalStaked: staked, totalStakedMultiplied: res.totalStakedMultiplied, stakedPercent: percent, TVL: tvl })
        } catch (e) { }
    }

    const updateLIQRLPStakedStats = async () => {
        try {
            const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, RpcProviders[selectedChainId], undefined)
            const res = await liqrStakingContract.poolInfo(BigNumber.from(1))
            const staked = res.totalStakedAmt
            const percent = Number(formatUnits(staked, LIQRLPInfo.decimals)) / Number(formatUnits(LIQRLPInfo.totalSupply, LIQRLPInfo.decimals)) * 100
            const tvl = Number(formatUnits(staked, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            setLIQRLPStakedStats({ totalStaked: staked, totalStakedMultiplied: res.totalStakedMultiplied, stakedPercent: percent, TVL: tvl })
        } catch (e) { }
    }

    const updateLPProviderStats = async () => {
        try {
            // const liqrTokenContract: Contract = getContract(LIQRInfo.address, LIQR_TOKEN_ABI, RpcProviders[selectedChainId], undefined)
            // // const totalDividendDistributed = await liqrTokenContract.totalDividendsDistributed()
            // // const value = Number(formatUnits(totalDividendDistributed, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            const value = Number(formatEther(ethPooled)) * ethPriceInUSD
            // const holders = await liqrTokenContract.getNumberOfDividendTokenHolders()
            let response = await fetch(`${GOPLUS_LABS_API_URL}/token_security/${selectedChainId}?contract_addresses=${LIQRInfo.address}`).then(res => res.json())
            let holders = LPProviderStats ? LPProviderStats.holders : 0
            if (response.code && response.result) {
                response = response.result[LIQRInfo.address.toLowerCase()]
                holders = Number(response.lp_holder_count) - 1
            }
            setLPProviderStats({ value: value, holders: Number(holders) })
        } catch (e) { console.log(e) }
    }

    const updateClaimableReflections = async () => {
        try {
            const liqrTokenContract: Contract = getContract(LIQRInfo.address, LIQR_TOKEN_ABI, RpcProviders[selectedChainId], undefined)
            const dividendTrackerCA = await liqrTokenContract.dividendTracker()
            const dividendTrackerContract: Contract = getContract(dividendTrackerCA, DIVIDEND_TRACKER_ABI, RpcProviders[selectedChainId], undefined)
            const claimableAmount = await liqrTokenContract.withdrawableDividendOf(account)
            let value = Number(formatUnits(claimableAmount, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            setClaimableReflections(claimableAmount)
            setValueOfClaimableReflections(value)
            let withdrawnAmount = await dividendTrackerContract.withdrawnDividendOf(account)
            withdrawnAmount = withdrawnAmount.add(claimableAmount)
            value = Number(formatUnits(withdrawnAmount, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            setLifetimeReflections(withdrawnAmount)
            setValueOfLifetimeReflections(value)
        } catch (e) { console.log(e) }
    }

    const claimReflections = async function () {
        if (!account || !provider) return
        const liqrTokenContract: Contract = getContract(LIQRInfo.address, LIQR_TOKEN_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrTokenContract.estimateGas.claim().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrTokenContract.claim({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const redeem = async function (amount: BigNumber) {
        if (!account || !provider) return
        const routerContract: Contract = getContract(UniswapRouterV2_Addresses[selectedChainId], ROUTER_ABI, provider as Web3Provider, account ? account : undefined)
        var deadline = Math.floor(Date.now() / 1000) + 900
        return routerContract.estimateGas.removeLiquidityETHSupportingFeeOnTransferTokens(LIQRInfo.address, amount, 0, 0, account, deadline).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return routerContract.removeLiquidityETHSupportingFeeOnTransferTokens(LIQRInfo.address, amount, 0, 0, account, deadline, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    useEffect(() => {
        updateBlockTimestamp()
    }, [fastRefresh])

    useEffect(() => {
        init()
        updateTokenInfos()
    }, [selectedChainId])

    useEffect(() => {
        initUser()
    }, [selectedChainId, account])

    useEffect(() => {
        updateETHPriceInUSD()
    }, [selectedChainId, slowRefresh])

    useEffect(() => {
        if (account) {
            if (LIQRInfo && LIQRLPInfo) updateClaimableReflections()
        } else {
            setClaimableReflections(BigNumber.from(0))
        }
    }, [account, LIQRLP_PriceInUSD, LIQRInfo, LIQRLPInfo, slowRefresh])

    useEffect(() => {
        if (LIQRInfo && ethPriceInUSD > 0) {
            updateLIQR_PriceInUSD()
        }
    }, [LIQRInfo, ethPriceInUSD, slowRefresh])

    useEffect(() => {
        if (LIQRInfo && LIQRLPInfo && ethPriceInUSD > 0) {
            updateLIQRLP_PriceInUSD()
        }
    }, [LIQRInfo, LIQRLPInfo, ethPriceInUSD, slowRefresh])

    useEffect(() => {
        if (LIQRInfo) {
            updateLIQRStakedStats()
        }
    }, [LIQRInfo, LIQR_PriceInUSD, slowRefresh])

    useEffect(() => {
        if (LIQRLPInfo) {
            updateLIQRLPStakedStats()
        }
    }, [LIQRLPInfo, LIQRLP_PriceInUSD, slowRefresh])

    // useEffect(() => {
    //     if (LIQRLPInfo && LIQRInfo) {
    //         updateLPProviderStats()
    //     }
    // }, [LIQRInfo, LIQRLPInfo, slowRefresh])

    useEffect(() => {
        if (LIQRLPInfo && LIQRInfo) {
            updateLPProviderStats()
        }
    }, [LIQRInfo, LIQRLPInfo, ethPooled, ethPriceInUSD, slowRefresh])

    return (
        <DashboardContext.Provider
            value={{
                LIQR_PriceInUSD,
                LIQRLP_PriceInUSD,
                ethPriceInUSD,
                LIQRInfo,
                LIQRLPInfo,
                LIQRStakedStats,
                LIQRLPStakedStats,
                LPProviderStats,
                blockTimestamp,
                ethPooled,
                liqrPooled,
                claimableReflections,
                valueOfClaimableReflections,
                lifetimeReflections,
                valueOfLifetimeReflections,
                updateLIQRStakedStats,
                updateLIQRLPStakedStats,
                updateClaimableReflections,
                claimReflections,
                updateLIQR_PriceInUSD,
                updateLIQRLP_PriceInUSD,
                redeem
            }}
        >
            {children}
        </DashboardContext.Provider >
    )
}

export const useDashboard = () => {
    const context = useContext(DashboardContext)

    if (!context) {
        throw new Error('Component rendered outside the provider tree')
    }

    return context
}

