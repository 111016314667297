import { calculateGasMargin, formatFixedNumber_Optimized, getContract } from 'src/utils'
import { BigNumber, Contract } from 'ethers'
import React, { useContext, useEffect, useState } from 'react'
import STAKE_ABI from 'src/constants/contracts/abis/stake.json'
import { TransactionResponse, Web3Provider } from '@ethersproject/providers'
import { LIQRStakingV2_CAs, RewardsPerDayBasedLIQRAmount, RewardsPerDayBasedLIQRLPAmount, RpcProviders } from 'src/constants/AppConstants'
import useRefresh from 'src/hooks/useRefresh'
import { useWeb3React } from '@web3-react/core'
import { useLIQR } from './LIQRContext'
import { useTokenBalanceCallback } from 'src/hooks/hooks'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { useDashboard } from './DashboardContext'
import useMemoizedState from 'src/hooks/useMemorizedState'

declare type Maybe<T> = T | null | undefined

export interface IUserStakedStats {
    stakedAmount: BigNumber
    valueOfStakedAmount: number
    unstakedAmount: BigNumber
    valueOfUnstakedAmount: number
    pendingRewards: BigNumber
    valueOfPendingRewards: number
    earnedAmount: BigNumber
    valueOfEarnedAmount: number
    reqUnlockTimestamp: number
    unlockTimestamp: number
    lastStakedTimestamp: number
}

export interface IStakingContext {
    LIQRLockDuration: number
    LIQRLPLockDuration: number
    userLIQRStakedStats: IUserStakedStats
    userLIQRLPStakedStats: IUserStakedStats
    payingOutLIQRLP_Rewards_OnCertainAmt: string
    payingOutLIQR_Rewards_OnCertainAmt: string
    LIQRPenalty: number
    LIQRLPPenalty: number
    updateLockDuration: () => Promise<any>
    updateUserLIQRStakedStats: () => Promise<any>
    updateUserLIQRLPStakedStats: () => Promise<any>
    stakeLIQR: (amount: BigNumber) => Promise<any>
    stakeLIQRLP: (amount: BigNumber) => Promise<any>
    unstakeAndClaimRewards: (pid: BigNumber) => Promise<any>
    unstake: (pid: BigNumber) => Promise<any>
    claimRewards: (pid: BigNumber) => Promise<any>
    compoundLIQR_Rewards: () => Promise<any>
    compoundLIQRLP_Rewards: () => Promise<any>
    compoundBoth_Rewards: () => Promise<any>
    requestToUnlock: (pid: BigNumber) => Promise<any>
    emergencyWithdraw: (pid: BigNumber) => Promise<any>
}

const StakingContext = React.createContext<Maybe<IStakingContext>>(null)

export const StakingProvider = ({ children = null as any }) => {
    const { account, provider } = useWeb3React()
    const { slowRefresh } = useRefresh()
    const { selectedChainId } = useLIQR()
    const [LIQRLockDuration, setLIQRLockDuration] = useState(0)
    const [LIQRLPLockDuration, setLIQRLPLockDuration] = useState(0)
    const [LIQRPenalty, setLIQRPenalty] = useState(0)
    const [LIQRLPPenalty, setLIQRLPPenalty] = useState(0)
    const [userLIQRStakedStats, setUserLIQRStakedStats] = useMemoizedState<IUserStakedStats>(undefined)
    const [userLIQRLPStakedStats, setUserLIQRLPStakedStats] = useMemoizedState<IUserStakedStats>(undefined)
    const { LIQRInfo, LIQRLPInfo, LIQR_PriceInUSD, LIQRLP_PriceInUSD, LIQRStakedStats, LIQRLPStakedStats } = useDashboard()
    const { tokenBalanceCallback } = useTokenBalanceCallback()
    const [rewardsPerDayOnStakeLIQR, setRewardsPerDayOnStakeLIQR] = useState(BigNumber.from(0))
    const [rewardsPerDayOnStakeLIQRLP, setRewardsPerDayOnStakeLIQRLP] = useState(BigNumber.from(0))
    const [payingOutLIQRLP_Rewards_OnCertainAmt, setPayingOutLIQRLP_Rewards_OnCertainAmt] = useState("--")
    const [payingOutLIQR_Rewards_OnCertainAmt, setPayingOutLIQR_Rewards_OnCertainAmt] = useState("--")

    const init = () => {
        setLIQRLockDuration(0)
        setLIQRLPLockDuration(0)
        setLIQRPenalty(0)
        setLIQRLPPenalty(0)
        setRewardsPerDayOnStakeLIQR(BigNumber.from(0))
        setRewardsPerDayOnStakeLIQRLP(BigNumber.from(0))
        setPayingOutLIQRLP_Rewards_OnCertainAmt("--")
        setPayingOutLIQR_Rewards_OnCertainAmt("--")
    }

    const initUser = () => {
        setUserLIQRStakedStats(undefined)
        setUserLIQRLPStakedStats(undefined)
    }

    const updateLockDuration = async () => {
        try {
            const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, RpcProviders[selectedChainId], undefined)
            let res = await liqrStakingContract.poolInfo(BigNumber.from(0))
            setLIQRLockDuration(Number(res.delaySecondsToUnlock))
            setLIQRPenalty(Number(res.penalty) / 100)
            res = await liqrStakingContract.poolInfo(BigNumber.from(0))
            setLIQRLPLockDuration(Number(res.delaySecondsToUnlock))
            setLIQRLPPenalty(Number(res.penalty) / 100)
        } catch (e) { }
    }

    const updateRewardsPerDay = async () => {
        try {
            const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, RpcProviders[selectedChainId], undefined)
            setRewardsPerDayOnStakeLIQR(await liqrStakingContract.getTodaysRewardsShared(BigNumber.from(0)))
            setRewardsPerDayOnStakeLIQRLP(await liqrStakingContract.getTodaysRewardsShared(BigNumber.from(1)))
        } catch (e) { console.log(e) }
    }

    const updateUserLIQRStakedStats = async () => {
        try {
            const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, RpcProviders[selectedChainId], undefined)
            const res = await liqrStakingContract.userInfo(BigNumber.from(0), account)
            const pending = await liqrStakingContract.pendingReward(BigNumber.from(0), account)
            const valueOfPending = Number(formatUnits(pending, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            const unstaked = await tokenBalanceCallback(LIQRInfo.address, selectedChainId)
            const valueOfStakedAmount = Number(formatUnits(res.stakedAmt, LIQRInfo.decimals)) * LIQR_PriceInUSD
            const valueOfUnstakedAmount = Number(formatUnits(unstaked, LIQRInfo.decimals)) * LIQR_PriceInUSD
            const valueOfEarnedAmount = Number(formatUnits(res.totalEarned, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            setUserLIQRStakedStats({ stakedAmount: res.stakedAmt, valueOfStakedAmount: valueOfStakedAmount, unstakedAmount: unstaked, valueOfUnstakedAmount: valueOfUnstakedAmount, pendingRewards: pending, valueOfPendingRewards: valueOfPending, earnedAmount: res.totalEarned, valueOfEarnedAmount: valueOfEarnedAmount, reqUnlockTimestamp: Number(res.reqUnlockTimestamp), unlockTimestamp: Number(res.unlockTimestamp), lastStakedTimestamp: Number(res.lastStakedTimestamp) })
        } catch (e) { console.log(e) }
    }

    const updateUserLIQRLPStakedStats = async () => {
        try {
            const liqrLPStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, RpcProviders[selectedChainId], undefined)
            const res = await liqrLPStakingContract.userInfo(BigNumber.from(1), account)
            const pending = await liqrLPStakingContract.pendingReward(BigNumber.from(1), account)
            const valueOfPending = Number(formatUnits(pending, LIQRInfo.decimals)) * LIQR_PriceInUSD
            const unstaked = await tokenBalanceCallback(LIQRLPInfo.address, selectedChainId)
            const valueOfStakedAmount = Number(formatUnits(res.stakedAmt, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            const valueOfUnstakedAmount = Number(formatUnits(unstaked, LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD
            const valueOfEarnedAmount = Number(formatUnits(res.totalEarned, LIQRInfo.decimals)) * LIQR_PriceInUSD
            setUserLIQRLPStakedStats({ stakedAmount: res.stakedAmt, valueOfStakedAmount: valueOfStakedAmount, unstakedAmount: unstaked, valueOfUnstakedAmount: valueOfUnstakedAmount, pendingRewards: pending, valueOfPendingRewards: valueOfPending, earnedAmount: res.totalEarned, valueOfEarnedAmount: valueOfEarnedAmount, reqUnlockTimestamp: Number(res.reqUnlockTimestamp), unlockTimestamp: Number(res.unlockTimestamp), lastStakedTimestamp: Number(res.lastStakedTimestamp) })
        } catch (e) { console.log(e) }
    }

    useEffect(() => {
        init()
        updateLockDuration()
    }, [selectedChainId])

    useEffect(() => {
        initUser()
    }, [selectedChainId, account])

    useEffect(() => {
        updateLockDuration()
        updateRewardsPerDay()
    }, [selectedChainId, slowRefresh])

    useEffect(() => {
        if (LIQRInfo && LIQRLPInfo && LIQRStakedStats) {
            setPayingOutLIQRLP_Rewards_OnCertainAmt(getRewardsPerDayBasedOnCertainAmtOfLIQR())
        }
    }, [rewardsPerDayOnStakeLIQR, LIQRInfo, LIQRLPInfo, LIQRStakedStats, LIQRLP_PriceInUSD])

    useEffect(() => {
        if (LIQRInfo && LIQRLPInfo && LIQRLPStakedStats) {
            setPayingOutLIQR_Rewards_OnCertainAmt(getRewardsPerDayBasedOnCertainAmtOfLIQRLP())
        }
    }, [rewardsPerDayOnStakeLIQR, LIQRInfo, LIQRLPInfo, LIQRLPStakedStats, LIQR_PriceInUSD])

    useEffect(() => {
        if (LIQRInfo && LIQRLPInfo && account) {
            updateUserLIQRStakedStats()
        }
    }, [account, LIQRInfo, LIQRLPInfo, LIQR_PriceInUSD, LIQRLP_PriceInUSD, selectedChainId, LIQRLockDuration, slowRefresh])

    useEffect(() => {
        if (LIQRInfo && LIQRLPInfo && account) {
            updateUserLIQRLPStakedStats()
        }
    }, [account, LIQRInfo, LIQRLPInfo, LIQR_PriceInUSD, LIQRLP_PriceInUSD, selectedChainId, LIQRLPLockDuration, slowRefresh])

    const stakeLIQR = async function (amount: BigNumber) {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.stake(BigNumber.from(0), amount).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.stake(BigNumber.from(0), amount, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const stakeLIQRLP = async function (amount: BigNumber) {
        if (!account || !provider) return
        const liqrLPStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrLPStakingContract.estimateGas.stake(BigNumber.from(1), amount).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrLPStakingContract.stake(BigNumber.from(1), amount, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const unstakeAndClaimRewards = async function (pid: BigNumber) {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.unstakeAndClaim(pid).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.unstakeAndClaim(pid, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const unstake = async function (pid: BigNumber) {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.unstake(pid).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.unstake(pid, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const claimRewards = async function (pid: BigNumber) {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.claim(pid).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.claim(pid, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const compoundLIQR_Rewards = async function () {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.compoundLIQR_Rewards().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.compoundLIQR_Rewards({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const compoundLIQRLP_Rewards = async function () {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.compoundLIQRLP_Rewards().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.compoundLIQRLP_Rewards({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const compoundBoth_Rewards = async function () {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.compoundBoth_Rewards().then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.compoundBoth_Rewards({ gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const requestToUnlock = async function (pid: BigNumber) {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.requestToUnlock(pid).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.requestToUnlock(pid, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const emergencyWithdraw = async function (pid: BigNumber) {
        if (!account || !provider) return
        const liqrStakingContract: Contract = getContract(LIQRStakingV2_CAs[selectedChainId], STAKE_ABI, provider as Web3Provider, account ? account : undefined)
        return liqrStakingContract.estimateGas.emergencyWithdraw(pid).then(estimatedGasLimit => {
            const gas = estimatedGasLimit
            return liqrStakingContract.emergencyWithdraw(pid, { gasLimit: calculateGasMargin(gas) }).then((response: TransactionResponse) => {
                return response.wait().then((res: any) => {
                    return {
                        status: res.status,
                        hash: response.hash
                    }
                })
            })
        })
    }

    const getRewardsPerDayBasedOnCertainAmtOfLIQR = () => {
        try {
            if (LIQRInfo && LIQRLPInfo && LIQRStakedStats) {
                if (LIQRStakedStats.totalStaked.gt(0)) {
                    return formatFixedNumber_Optimized(Number(formatUnits(rewardsPerDayOnStakeLIQR.mul(parseUnits(RewardsPerDayBasedLIQRAmount.toString(), LIQRInfo.decimals)).div(LIQRStakedStats.totalStaked), LIQRLPInfo.decimals)) * LIQRLP_PriceInUSD, 3, false)
                }
            }
        } catch (err) { console.log(err) }
        return "--"
    }

    const getRewardsPerDayBasedOnCertainAmtOfLIQRLP = () => {
        try {
            if (LIQRInfo && LIQRLPInfo && LIQRLPStakedStats) {
                if (LIQRLPStakedStats.totalStaked.gt(0)) {
                    return formatFixedNumber_Optimized(Number(formatUnits(rewardsPerDayOnStakeLIQRLP.mul(parseUnits(RewardsPerDayBasedLIQRLPAmount.toString(), LIQRLPInfo.decimals)).div(LIQRLPStakedStats.totalStaked), LIQRInfo.decimals)) * LIQR_PriceInUSD, 3, false)
                }
            }
        } catch (err) { }
        return "--"
    }

    return (
        <StakingContext.Provider
            value={{
                LIQRLockDuration,
                LIQRLPLockDuration,
                userLIQRStakedStats,
                userLIQRLPStakedStats,
                payingOutLIQRLP_Rewards_OnCertainAmt,
                payingOutLIQR_Rewards_OnCertainAmt,
                LIQRPenalty,
                LIQRLPPenalty,
                updateLockDuration,
                updateUserLIQRStakedStats,
                updateUserLIQRLPStakedStats,
                stakeLIQR,
                stakeLIQRLP,
                unstakeAndClaimRewards,
                unstake,
                claimRewards,
                compoundLIQR_Rewards,
                compoundLIQRLP_Rewards,
                compoundBoth_Rewards,
                requestToUnlock,
                emergencyWithdraw
            }}

        >
            {children}
        </StakingContext.Provider >
    )
}

export const useStaking = () => {
    const context = useContext(StakingContext)

    if (!context) {
        throw new Error('Component rendered outside the provider tree')
    }

    return context
}

