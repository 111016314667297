import { useState } from 'react'
import InputBoxContainer from 'src/common/components/InputBoxContainer'

interface InputBoxProps {
    value: number | string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur: () => void
}

export default function SlippageInputBoxExactFor({ value, onChange, onBlur }: InputBoxProps) {
    const [isBorder, setIsBorder] = useState(false)

    const handleFocus = () => {
        setIsBorder(true)
    }

    const handleBlur = () => {
        setIsBorder(false)
        onBlur()
    }

    return (
        <InputBoxContainer>
            <input
                type="number"
                className={`bg-app-content text-app-primary text-[16px] text-center block w-[32px] p-0 px-1 py-[3px] border ${isBorder ? 'border-white' : 'border-[#333]'} focus:outline-none`}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={value}
                onKeyPress={(e) => {
                    let ev: any = e.target;
                    (!(e.key >= '0' && e.key <= '9')) && e.preventDefault();
                }}
                onChange={(event) => {
                    onChange(event)
                }
                }
            />
        </InputBoxContainer>
    )
}