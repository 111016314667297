import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from "@emotion/react"
import theme from "src/theme/theme"
import Layout from 'src/common/layout/Layout'
import { DashboardProvider, RefreshContextProvider, StakingProvider } from "src/contexts"
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { SnackbarContextProvider } from 'src/hooks/useSnackbar'
import { Web3WalletProvider } from './common/We3ReactLibs/components/Web3WalletProvider'
import { LIQRProvider } from './contexts/LIQRContext'
import { AddLPProvider } from './contexts/AddLPContext'
import { ReferralSystemProvider } from './contexts/ReferralSystemContext'
import { Dashboard, ShillToEarn, LIQRStaking, LPStaking, AddLP, Swap } from './pages'
import { EmergencyUnstake } from './pages/emergency-unstake'
// import devTools from 'devtools-detect'

// const production = true

function App() {
  return (
    <Web3WalletProvider>
      <ThemeProvider theme={theme}>
        <RefreshContextProvider>
          <SnackbarContextProvider>
            <BrowserRouter>
              <LIQRProvider>
                <DashboardProvider>
                  <StakingProvider>
                    <AddLPProvider>
                      <ReferralSystemProvider>
                        <Layout>
                          <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/shill-to-earn" element={<ShillToEarn />} />
                            <Route path="/liqr-staking" element={<LIQRStaking />} />
                            <Route path="/lp-staking" element={<LPStaking />} />
                            <Route path="/add-lp" element={<AddLP />} />
                            <Route path="/emergency-unstake" element={<EmergencyUnstake />} />
                            <Route path="/swap/:code" element={<Swap />} />
                            <Route
                              path="*"
                              element={<Navigate to="/dashboard" replace />}
                            />
                          </Routes>
                        </Layout>
                      </ReferralSystemProvider>
                    </AddLPProvider>
                  </StakingProvider>
                </DashboardProvider>
              </LIQRProvider>
            </BrowserRouter>
          </SnackbarContextProvider>
          <ToastContainer />
        </RefreshContextProvider>
      </ThemeProvider>
    </Web3WalletProvider>
  )
}

export default App
