import React, { useState, useEffect, useRef } from 'react'
import LPQtyInput from 'src/common/components/LPQtyInput'
import { formatEther, isWrappedEther, getNativeSymbol, getFixedDecimals, getBigNumberFromInputString, decodeTxErrorMessage, getContract, formatEther_Optimized } from 'src/utils'
import { useApproveCallback, useNativeTokenBalance, useTokenAllowance, useTokenBalanceCallback } from 'src/hooks/hooks'
import useRefresh from 'src/hooks/useRefresh'
import { Wrapped_Ethers, Native_Currencies, UniswapRouterV2_Addresses, UniswapFactory_Addresses, RpcProviders } from 'src/constants/AppConstants'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { useSnackbar } from 'src/hooks/useSnackbar'
import { LoadingButton } from '@mui/lab'
import ConnectButton from 'src/common/ConnectButton'
import { debounce } from 'lodash'
import { Contract } from '@ethersproject/contracts'
import FACTORY_ABI from 'src/constants/contracts/abis/factory.json'
import { useAddLP } from 'src/contexts/AddLPContext'
import { useDashboard, useLIQR, useStaking } from 'src/contexts'
import AddLPToUniswapSuccess from './AddLPToUniswapSucess'

const SWAP_INPUT_ID = "id_swap_input"
const SWAP_OUTPUT_ID = "id_swap_output"
const SubBNBAmountOnMax = '0.01'

export default function AddingLPToUniswap() {
    const { account, chainId } = useWeb3React()
    const [token1Amount, setToken1Amount] = useState<BigNumber>(BigNumber.from(0))
    const [token2Amount, setToken2Amount] = useState<BigNumber>(BigNumber.from(0))
    const [token1, setToken1] = useState<any>()
    const [isProcessing, setIsProcessing] = useState(false)
    const [token2, setToken2] = useState<any>()
    const { tokenBalanceCallback, nativeBalanceCallback } = useTokenBalanceCallback()
    const [token1Balance, setToken1Balance] = useState(BigNumber.from(0))
    const [token2Balance, setToken2Balance] = useState(BigNumber.from(0))
    const [isLoadingToken1Balance, setIsLoadingToken1Balance] = useState(false)
    const [isLoadingToken2Balance, setIsLoadingToken2Balance] = useState(false)
    const [hash, setHash] = useState('')
    const [ethBalance, setEthBalance] = useState('--')
    const { LIQRInfo } = useDashboard()
    const { estimatedAddLiquidityToUniswap, addLiquidityToUniswap } = useAddLP()
    const { selectedChainId } = useLIQR()
    const nativeBalance = useNativeTokenBalance(selectedChainId ?? 1)
    const [estimatedGas, setEstimatedGas] = useState('--')
    const [isEstimatingGas, setIsEstimatingGas] = useState(false)
    const [isApprovedToken2, setIsApprovedToken2] = useState(false)
    const { fastRefresh } = useRefresh()
    const { tokenAllowanceCallback } = useTokenAllowance()
    const { approveCallback } = useApproveCallback()
    const [isCheckingAllowanceToken2, setIsCheckingAllowanceToken2] = useState(false)
    const [isWalletApprovingToken2, setIsWalletApprovingToken2] = useState(false)
    const [allowanceToken2, setAllowanceToken2] = useState<BigNumber | undefined>(undefined)
    const snackbar = useSnackbar()
    const [lpPair, setLPPair] = useState('')
    const { ethPooled, liqrPooled, updateLIQR_PriceInUSD, updateLIQRLP_PriceInUSD } = useDashboard()
    const { updateUserLIQRStakedStats, updateUserLIQRLPStakedStats } = useStaking()

    useEffect(() => {
        if (selectedChainId && LIQRInfo) {
            setToken1({ ...Wrapped_Ethers[selectedChainId as number], name: Native_Currencies[selectedChainId as number].name, symbol: Native_Currencies[selectedChainId as number].symbol })
            setToken2({ address: LIQRInfo.address, name: LIQRInfo.name, symbol: LIQRInfo.symbol, decimals: LIQRInfo.decimals, logoURI: "/lqr_icon.png" })
        }
    }, [selectedChainId, LIQRInfo])

    useEffect(() => {
        if (token1 && account) {
            callToken1BalanceCallback()
        }
    }, [token1, account])

    useEffect(() => {
        if (token2 && account) {
            callToken2BalanceCallback()
        }
    }, [token2, account])

    useEffect(() => {
        if (nativeBalance) {
            setEthBalance(formatEther(nativeBalance, Native_Currencies[selectedChainId ?? 1].decimals, 5, true))
        }
    }, [nativeBalance])

    useEffect(() => {
        if (!account) {
            setToken1Balance(BigNumber.from(0))
            setToken2Balance(BigNumber.from(0))
        }
    }, [account])

    useEffect(() => {
        if (token1 && token2) {
            initToken1Box()
            initToken2Box()
        }
    }, [token1, token2])

    useEffect(() => {
        if (token1 && token2 && token1Amount.gt(0) && token2Amount.gt(0) && !isProcessing) {
            if (account && isApprovedToken2) calcEstimatedGasForSwap()
            else setEstimatedGas('--')
        }
    }, [token1, token2, account, token1Amount, token2Amount, isApprovedToken2, fastRefresh])

    const calcEstimatedGasForSwap = useRef(
        debounce(async () => {
            setIsEstimatingGas(true)
        }, 500)
    ).current

    useEffect(() => {
        const fetch = async () => {
            await callEstimatedGasCallback()
            setIsEstimatingGas(false)
        }
        if (isEstimatingGas) {
            fetch()
        }
    }, [isEstimatingGas])

    const callEstimatedGasCallback = async () => {
        try {
            let res
            res = await estimatedAddLiquidityToUniswap(token1Amount, token2.address, token2Amount, BigNumber.from(0), BigNumber.from(0))
            if (res) setEstimatedGas(formatEther(res, 18, 5, true))
            else setEstimatedGas('--')
        } catch (error) {
            setEstimatedGas('--')
            console.error('Failed to estimate gas', error)
        }
    }

    const getFixedAmount = (res: BigNumber, decimals: number) => {
        let toFixed = 3
        if (formatUnits(res, decimals).substring(0, 2) === '0.') {
            toFixed = getFixedDecimals(Number(formatUnits(res, decimals)), 3)
        }
        toFixed = Math.min(decimals, toFixed)
        return parseUnits(formatEther(res, decimals, toFixed, false), decimals)
    }

    const callNativeBalanceCallback = async () => {
        try {
            let res = await nativeBalanceCallback(selectedChainId as number)
            setEthBalance(formatEther(res, Native_Currencies[selectedChainId ?? 1].decimals, 5, true))
        } catch (error) {
            setEthBalance('--')
            console.error('Failed to get native balance', error)
        }
    }

    const callToken1BalanceCallback = async () => {
        setIsLoadingToken1Balance(true)
        try {
            let res = BigNumber.from(0)
            if (isWrappedEther(selectedChainId as number, token1?.address)) {
                res = await nativeBalanceCallback(selectedChainId as number)
            } else {
                res = await tokenBalanceCallback(token1?.address, selectedChainId as number)
            }
            setToken1Balance(res)
        } catch (error) {
            setToken1Balance(BigNumber.from(0))
            console.error('Failed to get tokenA balance', error)
        }
        setIsLoadingToken1Balance(false)
    }

    const callToken2BalanceCallback = async () => {
        setIsLoadingToken2Balance(true)
        try {
            let res = BigNumber.from(0)
            if (isWrappedEther(selectedChainId as number, token2?.address)) {
                res = await nativeBalanceCallback(selectedChainId as number)
            } else {
                res = await tokenBalanceCallback(token2?.address, selectedChainId as number)
            }
            setToken2Balance(res)
        } catch (error) {
            setToken2Balance(BigNumber.from(0))
            console.error('Failed to get tokenB balance', error)
        }
        setIsLoadingToken2Balance(false)
    }

    const initToken1Box = () => {
        setToken1Amount(BigNumber.from(0))
        let element: any = document.getElementById(SWAP_INPUT_ID)
        if (element) element.value = ""
    }

    const initToken2Box = () => {
        setToken2Amount(BigNumber.from(0))
        let element: any = document.getElementById(SWAP_OUTPUT_ID)
        if (element) element.value = ""
    }

    const setInputBoxValue = (val: BigNumber) => {
        let element: any = document.getElementById(SWAP_INPUT_ID)
        if (element) element.value = formatUnits(val, token1?.decimals)
    }

    const setOutputBoxValue = (val: BigNumber) => {
        let element: any = document.getElementById(SWAP_OUTPUT_ID)
        if (element) element.value = formatUnits(val, token2?.decimals)
    }

    const autoCalcOutput = (amount: BigNumber) => {
        if (ethPooled.gt(0)) {
            let amountB = amount.mul(liqrPooled).div(ethPooled)
            if (LIQRInfo) amountB = parseUnits(formatEther_Optimized(amountB, LIQRInfo.decimals, 3, false), LIQRInfo.decimals)
            // if (amountB.gt(token2Balance) && account) amountB = token2Balance
            setOutputBoxValue(amountB)
        }
    }

    const onInputChange = (val: string) => {
        let amount = getBigNumberFromInputString(val, token1?.decimals)
        setToken1Amount(amount)
        autoCalcOutput(amount)
    }

    const autoCalcInput = (amount: BigNumber) => {
        if (liqrPooled.gt(0)) {
            let amountA = amount.mul(ethPooled).div(liqrPooled)
            amountA = parseUnits(formatEther_Optimized(amountA, Wrapped_Ethers[selectedChainId as number].decimals, 3, false), Wrapped_Ethers[selectedChainId as number].decimals)
            // if (amountA.gt(token1Balance) && account) amountA = token1Balance
            setInputBoxValue(amountA)
        }
    }

    const onOutputChange = (val: string) => {
        let amount = getBigNumberFromInputString(val, token2?.decimals)
        setToken2Amount(amount)
        autoCalcInput(amount)
    }

    const onToken1Max = () => {
        let maxAbleBal = token1Balance
        if (isWrappedEther(selectedChainId as number, token1?.address)) {
            const SubBNBOnMax: BigNumber = parseUnits(SubBNBAmountOnMax, token1?.decimals)
            maxAbleBal = token1Balance.gte(SubBNBOnMax) ? token1Balance.sub(SubBNBOnMax) : BigNumber.from(0)
        }
        maxAbleBal = getFixedAmount(maxAbleBal, token1?.decimals)
        setToken1Amount(maxAbleBal)
        setInputBoxValue(maxAbleBal)
        autoCalcOutput(maxAbleBal)
    }

    const onToken2Max = () => {
        let maxAbleBal = token2Balance
        if (isWrappedEther(selectedChainId as number, token2?.address)) {
            const SubBNBOnMax: BigNumber = parseUnits(SubBNBAmountOnMax, token2?.decimals)
            maxAbleBal = token2Balance.gte(SubBNBOnMax) ? token2Balance.sub(SubBNBOnMax) : BigNumber.from(0)
        }
        maxAbleBal = getFixedAmount(maxAbleBal, token2?.decimals)
        setToken2Amount(maxAbleBal)
        setOutputBoxValue(maxAbleBal)
        autoCalcInput(maxAbleBal)
    }

    const setLPSuccess = (hash: string) => {
        initToken1Box()
        initToken2Box()
        setHash(hash)
        callNativeBalanceCallback()
        callToken1BalanceCallback()
        callToken2BalanceCallback()
    }

    const checkAllowanceToken2 = async (router): Promise<boolean> => {
        let amount = token2Amount
        if (allowanceToken2) {
            if (allowanceToken2.gt(0)) return allowanceToken2.gte(amount) && amount.gt(0)
        }
        try {
            let contractAddress = router
            let res = await tokenAllowanceCallback(account ?? "", contractAddress, token2?.address, selectedChainId as number)
            setAllowanceToken2(res)
            return res.gte(amount) && amount.gt(0)
        } catch (error) {
            console.log(error)
            return false
        }

    }

    useEffect(() => {
        setAllowanceToken2(undefined)
    }, [account])

    useEffect(() => {
        const fetch = async () => {
            let res = await checkAllowanceToken2(UniswapRouterV2_Addresses[selectedChainId])
            setIsApprovedToken2(res)
            setIsCheckingAllowanceToken2(false)
        }
        if (isCheckingAllowanceToken2) {
            fetch()
        }
    }, [isCheckingAllowanceToken2])

    useEffect(() => {
        const fetch = async () => {
            if (isWrappedEther(selectedChainId as number, token2?.address)) {
                setAllowanceToken2(BigNumber.from(0))
                setIsApprovedToken2(true)
            }
            if (account && !isWrappedEther(selectedChainId as number, token2?.address) && token2Amount.gt(0)) {
                const approved = await checkAllowanceToken2(UniswapRouterV2_Addresses[selectedChainId])
                setIsApprovedToken2(approved)
            }
        }

        if (token2) {
            fetch()
        } else {
            setAllowanceToken2(undefined)
            setIsApprovedToken2(false)
        }
    }, [token2, token2Amount, account])

    useEffect(() => {
        setAllowanceToken2(undefined)
    }, [token2])

    const onApproveToken2 = async () => {
        setIsWalletApprovingToken2(true)
        let res = await checkAllowanceToken2(UniswapRouterV2_Addresses[selectedChainId])
        let contractAddress = UniswapRouterV2_Addresses[selectedChainId]
        if (!res) {
            try {
                await approveCallback(contractAddress, token2?.address).then(res => {
                    setAllowanceToken2(res.allowance)
                    setIsApprovedToken2(true)
                    snackbar.snackbar.show("Approved!", "success")
                }).catch((error: any) => {
                    console.log(error)
                    let err: any = error
                    snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
                })
            } catch (error) {
                console.log(error)
            }
        } else {
            snackbar.snackbar.show("Approved!", "success")
            setIsApprovedToken2(true)
        }
        setIsWalletApprovingToken2(false)
        return null
    }

    const onAddLP = async () => {
        setIsProcessing(true)
        try {
            await addLiquidityToUniswap(token1Amount, token2.address, token2Amount, BigNumber.from(0), BigNumber.from(0)).then(async (res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("LP successfully added!", "success")
                    const factory: Contract = getContract(UniswapFactory_Addresses[selectedChainId], FACTORY_ABI, RpcProviders[selectedChainId], account ? account : undefined)
                    const pair = await factory.getPair(Wrapped_Ethers[selectedChainId].address, token2?.address)
                    setLPPair(pair)
                    setLPSuccess(res.hash)
                    setAllowanceToken2(undefined)
                    updateUserLIQRStakedStats()
                    updateUserLIQRLPStakedStats()
                    updateLIQR_PriceInUSD()
                    updateLIQRLP_PriceInUSD()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsProcessing(false)
    }

    return (
        <div className="w-full flex-1 fit-full-h mt-6">
            {/* {
                !hash && ( */}
            <div className='w-full flex justify-center items-center flex-1 fit-full-h'>
                <div className='w-full max-w-[640px] rounded-2xl bg-[#101215] p-4 flex flex-col gap-2 mt-2'>
                    <div className='w-full flex gap-6 justify-center my-2 items-center'>
                        <div className='flex justify-center items-center rounded-2xl bg-app-content box-border'>
                            {token1 && <div className='flex gap-2 py-2 px-3 justify-left items-center'>
                                <div className="flex items-center justify-center w-6 h-6">
                                    <img src={token1?.logoURI} width="22" height="22" />
                                </div>
                                <div className='uppercase text-white text-[13px] sm:text-[14px] leading-[1.1] whitespace-nowrap'>{token1?.symbol}</div>
                            </div>}
                            <div className='text-[30px]'>
                                +
                            </div>
                            {token2 && <div className='flex gap-2 py-2 px-3 justify-left items-center'>
                                <div className="flex items-center justify-center w-6 h-6">
                                    <img src={token2?.logoURI} width="22" height="22" />
                                </div>
                                <div className='uppercase text-white text-[13px] sm:text-[14px] leading-[1.1] whitespace-nowrap'>{token2?.symbol}</div>
                            </div>}
                        </div>
                        <div className='text-[24px]'>
                            Pair
                        </div>
                    </div>
                    <LPQtyInput
                        id={SWAP_INPUT_ID}
                        name={token1 ? token1?.symbol : undefined}
                        decimals={token1 ? token1?.decimals : 18}
                        isAvailableMaxBtn={!isLoadingToken1Balance && token1Balance.gt(0)}
                        balance={isLoadingToken1Balance ? "--" : formatEther(token1Balance, token1?.decimals, getFixedDecimals(Number(formatUnits(token1Balance, token1?.decimals)), 3), true)}
                        onChange={(val: any) => onInputChange(val)}
                        logoURI={token1?.logoURI}
                        onOpenSelectModal={() => { }}
                        onMax={onToken1Max} />
                    <div className='w-full text-center text-[36px]'>
                        +
                    </div>
                    <LPQtyInput
                        id={SWAP_OUTPUT_ID}
                        name={token2 ? token2?.symbol : undefined}
                        decimals={token2 ? token2?.decimals : 18}
                        isAvailableMaxBtn={!isLoadingToken2Balance && token2Balance.gt(0)}
                        balance={isLoadingToken2Balance ? "--" : formatEther(token2Balance, token2?.decimals, getFixedDecimals(Number(formatUnits(token2Balance, token2?.decimals)), 3), true)}
                        onChange={(val: any) => onOutputChange(val)}
                        logoURI={token2?.logoURI}
                        onOpenSelectModal={() => { }}
                        onMax={onToken2Max} />
                    <div className='mt-1 text-white text-[14px] flex justify-between mx-1 leading-[1.1]'>
                        <div>Native Coin Balance</div>
                        <div className='text-right'>{`${ethBalance} ${getNativeSymbol(selectedChainId as number)}`}</div>
                    </div>
                    {isApprovedToken2 && (token1Amount.gt(0) && token2Amount.gt(0)) && account && (<div className='mt-1 text-white text-[14px] flex justify-between mx-1 leading-[1.1]'>
                        <div>Estimated Gas</div>
                        <div className='text-right'>{`${estimatedGas} ${getNativeSymbol(selectedChainId as number)}`}</div>
                    </div>)}
                    {token1Amount.gt(token1Balance) && account && <div className='mt-1 text text-[#ffff77] text-[14px] leading-[1.1]'>{`Insufficient balance of ${token1?.symbol}`}</div>}
                    {token2Amount.gt(token2Balance) && account && <div className='mt-1 text text-[#ffff77] text-[14px] leading-[1.1]'>{`Insufficient balance of ${token2?.symbol}`}</div>}
                    <div className='flex gap-4 mt-2'>
                        {account ? <>
                            <LoadingButton
                                variant="contained"
                                sx={{ width: "100%", borderRadius: "9999px", height: '45px' }}
                                loading={isWalletApprovingToken2}
                                loadingPosition="start"
                                onClick={onApproveToken2}
                                disabled={isApprovedToken2 || !allowanceToken2 || isCheckingAllowanceToken2 || !account || token2Amount.lte(0) || token2Balance.lt(token2Amount)}
                            >
                                {isWalletApprovingToken2 ? 'Approving ...' : isApprovedToken2 ? "Approved" : `Approve ${token2?.symbol}`}
                            </LoadingButton>
                            <LoadingButton
                                variant="contained"
                                sx={{ width: "100%", borderRadius: "9999px", height: '45px' }}
                                loading={isProcessing}
                                loadingPosition="start"
                                onClick={onAddLP}
                                disabled={!isApprovedToken2 || !allowanceToken2 || !account || token1Amount.lte(0) || token1Balance.lt(token1Amount) || token2Amount.lte(0) || token2Balance.lt(token2Amount)}
                            >
                                {isProcessing ? 'Adding ...' : "Add"}
                            </LoadingButton>
                        </> :
                            <ConnectButton />
                        }
                    </div>
                </div>
            </div>
            {/* )
            }
            {
                hash && lpPair && (
                    <AddLPToUniswapSuccess lpPair={lpPair} />
                )
            } */}
        </div >
    )
}

