import Button from "@mui/material/Button"
import { useWallet } from "./We3ReactLibs/components/Web3WalletProvider"

const ConnectButton = () => {
    const { setIsOpenConnectModal } = useWallet()

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={() => setIsOpenConnectModal(true)}
            sx={{ borderRadius: "9999px", width: '100%' }}
        >
            <span className="text-[16px] md:text-[18px]">Connect</span>
        </Button>
    )
}

export default ConnectButton