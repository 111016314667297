import LoadingButton from "@mui/lab/LoadingButton/LoadingButton"
import Button from "@mui/material/Button"
import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import Modal from "src/common/components/Modal"
import { BASE_REFERRAL_URL } from "src/constants/AppConstants"
import { useReferralSystem } from "src/contexts/ReferralSystemContext"
import { useSnackbar } from "src/hooks/useSnackbar"
import { copyTextToClipboard, decodeTxErrorMessage } from "src/utils"
import ReferralCodeInput from "./ReferralCodeInputBox"
import { v4 } from "uuid"
import Tooltip from "@mui/material/Tooltip"

export const CreateCustomLink = () => {
    const { yourReferralCode, createReferralCode, updateYourReferralCode } = useReferralSystem()
    const [isOpenCustomLinkPopup, setIsOpenCustomLinkPopup] = useState(false)
    const [isCreatingReferralCode, setIsCreatingReferralCode] = useState(false)
    const { account } = useWeb3React()
    const [inputCode, setInputCode] = useState('')
    const snackbar = useSnackbar()
    const [isBorder, setIsBorder] = useState(false)
    const { totalFee, referralFee, buyFeeOnUniswap } = useReferralSystem()
    const [isCopied, setIsCopied] = useState(false)

    const copyAddress = (copyStr: string) => {
        copyTextToClipboard(copyStr)
            .then(() => {
                setIsCopied(true)
                setTimeout(() => {
                    setIsCopied(false)
                }, 3000)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const onCreateReferralCode = async () => {
        setIsCreatingReferralCode(true)
        try {
            await createReferralCode(inputCode).then(async (res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Created your referral code successfully!", "success")
                    await updateYourReferralCode()
                    setIsOpenCustomLinkPopup(false)
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsCreatingReferralCode(false)
    }

    const setFocus = (v: boolean) => {
        setIsBorder(v)
    }

    const handleInputCodeChange = async (code: string) => {
        setInputCode(code)
    }

    const onGenerateCode = () => {
        // const code = Math.random().toString(36).substring(2, 8)
        let code = v4().split("-")
        if (code.length > 1) {
            setInputCode(code[0] + "-" + code[1])
        }

    }

    useEffect(() => {
        setInputCode(yourReferralCode)
    }, [yourReferralCode])

    return (
        <div className="w-full">
            <Modal handleClose={() => setIsOpenCustomLinkPopup(false)} header={"Create referral code"} isOpen={isOpenCustomLinkPopup}>
                <div className="w-full flex items-center gap-2">
                    <ReferralCodeInput
                        setFocus={setFocus}
                        onChange={handleInputCodeChange}
                        placeholder={"Enter your referral code"}
                        title={"Referral Code"}
                        readOnly={false}
                        value={inputCode}
                    />
                    <Button
                        variant="contained"
                        sx={{ width: "90px", borderRadius: "9999px", height: '48px' }}
                        onClick={onGenerateCode}
                    >
                        <span className="text-[16px] leading-[1.1] text-center">Generate</span>
                    </Button>
                </div>
                <div className="w-full px-4 mt-6">
                    <LoadingButton
                        variant="outlined"
                        sx={{ width: "100%", borderRadius: "9999px", height: '48px' }}
                        loading={isCreatingReferralCode}
                        loadingPosition="start"
                        onClick={() => onCreateReferralCode()}
                        disabled={!account || (yourReferralCode === inputCode)}
                    >
                        <span className="text-[16px] leading-[1.1] text-center">{isCreatingReferralCode ? 'Creating ...' : 'Create Your Referral Code'}</span>
                    </LoadingButton>
                </div>
            </Modal>
            <div className="w-full flex justify-center items-center flex-wrap gap-4 text-center text-[14px] text-white">
                {yourReferralCode.length > 0 ?
                    <div className="flex items-center gap-1">
                        <div>{`Your URL is `}<span className="">{BASE_REFERRAL_URL + yourReferralCode}</span></div>
                        <Tooltip title="Copy link" placement="bottom" componentsProps={{ tooltip: { sx: { backgroundColor: '#142028', }, } }}>
                            <div className='cursor-pointer' onClick={() => copyAddress(BASE_REFERRAL_URL + yourReferralCode)}>
                                <img src='/images/copy.png' width="20" />
                            </div>
                        </Tooltip>
                        <div className='flex justify-end mt-2'>
                            <div className="relative">
                                <div className='absolute right-0 top-0'>
                                    {isCopied && <span className='text-[10px] text-white font-light whitespace-nowrap '>Link copied</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>{`Referral link not set`}</div>
                }
                <div className="text-[16px] text-[#62B414] font-bold cursor-pointer underline" onClick={() => setIsOpenCustomLinkPopup(true)}>
                    Create Custom Link
                </div>
            </div>
        </div>
    )
}