import React, { useState } from 'react'
import TokenQtyInputBox from 'src/common/components/TokenQtyInputBox'

interface InputProps {
    id: string
    title: string
    readOnly: boolean
    onChange: (val: any) => void
    onMax: () => void
}

export default function TokenQtyInput({ id, title, readOnly, onChange, onMax }: InputProps) {
    const [isBorder, setIsBorder] = useState(false)

    const handleFocus = () => {
        setIsBorder(true)
    }

    const handleBlur = () => {
        setIsBorder(false)
    }

    return (
        <div className={`w-full flex flex-col rounded-md flex-1 bg-app-content py-2 gap-[2px] px-4 xl:px-5 ${isBorder ? 'border border-app-primary' : ''}`}>
            <div className='text-[12px] text-app-gray'>{title}</div>
            <div className='w-full flex items-center gap-2'>
                <div className='w-full'>
                    <TokenQtyInputBox
                        handleFocus={handleFocus}
                        handleBlur={handleBlur}
                        decimals={undefined}
                        onChange={onChange}
                        id={id}
                        readOnly={readOnly}
                    />
                </div>
                <div className='text-[14px] p-2 cursor-pointer text-app-primary' onClick={onMax}>MAX</div>
            </div>
        </div>
    )
}