import { Slider } from '@mui/material'
import React, { useState, useEffect } from 'react'
import SlippageInputBoxExactFor from './SlippageInputBoxExactFor'
import { useWeb3React } from '@web3-react/core'

interface InputProps {
    slippageExactFor: number | string
    setSlippageExactFor: (v: number | string) => void
    onChangeSlippageExactFor: (event: Event, newValue: number | number[]) => void
}

export const Slider_ExactFor_Min = 1
export const Slider_ExactFor_Max = 99

export default function SlippageBox(
    {
        slippageExactFor,
        setSlippageExactFor,
        onChangeSlippageExactFor
    }: InputProps) {
    const { account } = useWeb3React()
    const [sliderMarksExactFor, setSliderMarksExactFor] = useState<any[]>([])
    const [strInputExactFor, setStrInputExactFor] = useState()

    useEffect(() => {
        let temp: any[] = []
        for (let i = Slider_ExactFor_Min; i <= Slider_ExactFor_Max; i++) {
            if (i === Slider_ExactFor_Min || i === Slider_ExactFor_Max) {
                temp.push({ value: i, label: `${i}%` })
            } else {
                temp.push({ value: i, label: '' })
            }
        }
        setSliderMarksExactFor(temp)
    }, [])

    const handleInputExactForChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSlippageExactFor(event.target.value === '' ? '' : Number(event.target.value))
    }

    const handleInputExactForBlur = () => {
        if (slippageExactFor ?? 0 < Slider_ExactFor_Min) {
            setSlippageExactFor(Slider_ExactFor_Min)
        } else if (slippageExactFor ?? 0 > Slider_ExactFor_Max) {
            setSlippageExactFor(Slider_ExactFor_Max)
        }
    }

    return (
        <div className='w-full flex flex-col'>
            <div className='w-full flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                    <div className='text-white text-[14px]'>
                        Slippage
                    </div>
                    <div className='flex items-center gap-[2px]'>
                        <SlippageInputBoxExactFor value={slippageExactFor} onChange={handleInputExactForChange} onBlur={handleInputExactForBlur} />
                        <div className='text-white text-[16px]'>%</div>
                    </div>
                </div>
            </div>
            <div className={`w-full`}>
                <div className='w-full px-4'>
                    <Slider
                        // size="small"
                        value={typeof slippageExactFor === 'number' ? slippageExactFor : Slider_ExactFor_Min}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        defaultValue={25}
                        getAriaValueText={(value: number) => `${value}%`}
                        step={1}
                        color="primary"
                        sx={{ color: "#FFF" }}
                        onChange={onChangeSlippageExactFor}
                        min={Slider_ExactFor_Min}
                        max={Slider_ExactFor_Max}
                        marks={sliderMarksExactFor}
                        disabled={!account}
                        aria-labelledby="input-slider"
                    />
                </div>
            </div>
        </div>
    )
}