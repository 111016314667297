import React, { useContext, useState } from 'react'
import { CHAIN_ITEMS } from 'src/common/layout/SupportChainsConstants'

declare type Maybe<T> = T | null | undefined

export interface ILIQRContext {
    selectedChainId: number
    setSelectedChainId: (v: number) => void
}

const LIQRContext = React.createContext<Maybe<ILIQRContext>>(null)

export const LIQRProvider = ({ children = null as any }) => {
    const [selectedChainId, setSelectedChainId] = useState(CHAIN_ITEMS[Object.keys(CHAIN_ITEMS)[0]])

    return (
        <LIQRContext.Provider
            value={{
                selectedChainId,
                setSelectedChainId
            }}
        >
            {children}
        </LIQRContext.Provider >
    )
}

export const useLIQR = () => {
    const context = useContext(LIQRContext)

    if (!context) {
        throw new Error('Component rendered outside the provider tree')
    }

    return context
}
