import { Contract } from "@ethersproject/contracts"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Native_Currencies, ReferralSystem_CAs, RpcProviders, Wrapped_Ethers } from "src/constants/AppConstants"
import { decodeTxErrorMessage, formatEther, formatEther_Optimized, getBigNumberFromInputString, getContract, getFixedDecimals, getNativeSymbol, isAddress, isWrappedEther } from "src/utils"
import REFERRALSYSTEM_ABI from 'src/constants/contracts/abis/referralSystem.json'
import { useDashboard, useLIQR, useStaking } from "src/contexts"
import { useWeb3React } from "@web3-react/core"
import { AddressZero } from '@ethersproject/constants'
import { useReferralSystem } from "src/contexts/ReferralSystemContext"
import { useSnackbar } from "src/hooks/useSnackbar"
import { BigNumber } from "@ethersproject/bignumber"
import { useTokenBalanceCallback } from "src/hooks/hooks"
import { debounce } from 'lodash'
import useRefresh from "src/hooks/useRefresh"
import { formatUnits, parseUnits } from "@ethersproject/units"
import LoadingButton from "@mui/lab/LoadingButton"
import ConnectButton from "src/common/ConnectButton"
import LIQRQtyOutput from "src/common/components/LIQRQtyOutput"
import LPQtyInput from "src/common/components/LPQtyInput"
import SlippageBox, { Slider_ExactFor_Min } from "./SlippageBox"

const SWAP_INPUT_ID = "id_swap_input"
const SWAP_OUTPUT_ID = "id_swap_output"
const SubBNBAmountOnMax = '0.01'

export const Swap = () => {
    const { code } = useParams()
    const { selectedChainId } = useLIQR()
    const { account } = useWeb3React()
    const navigate = useNavigate()
    const { setReferrerInfo, estimatedBuyWithReferral, buyWithReferral, getAmountOut, getSwapAmountOutMin } = useReferralSystem()
    const [ethAmount, setETHAmount] = useState<BigNumber>(BigNumber.from(0))
    const [liqrAmount, setLIQRAmount] = useState<BigNumber>(BigNumber.from(0))
    const [ethToken, setETHToken] = useState<any>()
    const [isProcessing, setIsProcessing] = useState(false)
    const [liqrToken, setLIQRToken] = useState<any>()
    const { tokenBalanceCallback, nativeBalanceCallback } = useTokenBalanceCallback()
    const [ethBalance, setETHBalance] = useState(BigNumber.from(0))
    const [liqrBalance, setLIQRBalance] = useState(BigNumber.from(0))
    const [isLoadingETHBalance, setIsLoadingETHBalance] = useState(false)
    const [isLoadingLIQRBalance, setIsLoadingLIQRBalance] = useState(false)
    const [estimatedGas, setEstimatedGas] = useState('--')
    const [isEstimatingGas, setIsEstimatingGas] = useState(false)
    const [isEstimatingOutAmount, setIsEstimatingOutAmount] = useState(false)
    const { updateUserLIQRStakedStats, updateUserLIQRLPStakedStats } = useStaking()
    const snackbar = useSnackbar()
    const { LIQRInfo, updateLIQR_PriceInUSD, updateLIQRLP_PriceInUSD } = useDashboard()
    const { fastRefresh } = useRefresh()
    const [hash, setHash] = useState('')
    const [slippageExactFor, setSlippageExactFor] = useState<number | string>(10) // 10%
    const [writeEstimatingOut_flag, setWriteEstimatingOut_flag] = useState(false)
    const [queueEstimatingData, setQueueEstimatingData] = useState({ res: undefined, preInAmount: BigNumber.from(0) })
    const [estimatedAmountOut, setEstimatedAmountOut] = useState(BigNumber.from(0))
    const [minReceived, setMinReceived] = useState(BigNumber.from(0))

    useEffect(() => {
        if (selectedChainId && LIQRInfo) {
            setETHToken({ ...Wrapped_Ethers[selectedChainId as number], name: Native_Currencies[selectedChainId as number].name, symbol: Native_Currencies[selectedChainId as number].symbol })
            setLIQRToken({ address: LIQRInfo.address, name: LIQRInfo.name, symbol: LIQRInfo.symbol, decimals: LIQRInfo.decimals, logoURI: "/lqr_icon.png" })
        }
    }, [selectedChainId, LIQRInfo])

    useEffect(() => {
        if (ethToken && account) {
            callETHBalanceCallback()
        }
    }, [ethToken, account])

    useEffect(() => {
        if (liqrToken && account) {
            callLIQRBalanceCallback()
        }
    }, [liqrToken, account])

    useEffect(() => {
        if (!account) {
            setETHBalance(BigNumber.from(0))
            setLIQRBalance(BigNumber.from(0))
        }
    }, [account])

    useEffect(() => {
        if (ethToken && liqrToken) {
            initETHBox()
            initLIQRBox()
        }
    }, [ethToken, liqrToken])

    useEffect(() => {
        if (ethToken && liqrToken && !isProcessing) {
            if (account && ethAmount.gt(0)) calcEstimatedGasForSwap()
            else setEstimatedGas('--')

            if (ethAmount.gt(0)) {
                calcEstimatedOutAmount()
            } else {
                initLIQRBox()
            }
        }
    }, [ethToken, liqrToken, account, ethAmount, fastRefresh])

    const calcEstimatedGasForSwap = useRef(
        debounce(async () => {
            setIsEstimatingGas(true)
        }, 500)
    ).current

    const calcEstimatedOutAmount = useRef(
        debounce(async () => {
            setIsEstimatingOutAmount(true)
        }, 500)
    ).current

    useEffect(() => {
        const fetch = async () => {
            await callEstimatedGasCallback()
            setIsEstimatingGas(false)
        }
        if (isEstimatingGas) {
            fetch()
        }
    }, [isEstimatingGas])

    useEffect(() => {
        const fetch = async () => {
            await callEstimatedOutAmountCallback()
            setIsEstimatingOutAmount(false)
        }
        if (isEstimatingOutAmount) {
            fetch()
        }
    }, [isEstimatingOutAmount])

    useEffect(() => {
        const slippage = getSlippageExactFor()
        const amountOutMin = getSwapAmountOutMin(estimatedAmountOut, slippage)
        setMinReceived(amountOutMin)
    }, [estimatedAmountOut, slippageExactFor])

    const getSlippageExactFor = () => {
        const slippage = Number(slippageExactFor) ?? Slider_ExactFor_Min
        return slippage
    }

    const callEstimatedGasCallback = async () => {
        try {
            let res
            res = await estimatedBuyWithReferral(ethAmount, getSlippageExactFor())
            if (res) setEstimatedGas(formatEther(res, 18, 5, true))
            else setEstimatedGas('--')
        } catch (error) {
            setEstimatedGas('--')
            console.error('Failed to estimate gas', error)
        }
    }

    const getFixedAmount = (res: BigNumber, decimals: number) => {
        let toFixed = 3
        if (formatUnits(res, decimals).substring(0, 2) === '0.') {
            toFixed = getFixedDecimals(Number(formatUnits(res, decimals)), 3)
        }
        toFixed = Math.min(decimals, toFixed)
        return parseUnits(formatEther(res, decimals, toFixed, false), decimals)
    }

    const callETHBalanceCallback = async () => {
        setIsLoadingETHBalance(true)
        try {
            let res = BigNumber.from(0)
            res = await nativeBalanceCallback(selectedChainId as number)
            setETHBalance(res)
        } catch (error) {
            setETHBalance(BigNumber.from(0))
            console.error('Failed to get tokenA balance', error)
        }
        setIsLoadingETHBalance(false)
    }

    const callLIQRBalanceCallback = async () => {
        setIsLoadingLIQRBalance(true)
        try {
            let res = BigNumber.from(0)
            res = await tokenBalanceCallback(liqrToken?.address, selectedChainId as number)
            setLIQRBalance(res)
        } catch (error) {
            setLIQRBalance(BigNumber.from(0))
            console.error('Failed to get tokenB balance', error)
        }
        setIsLoadingLIQRBalance(false)
    }

    const initETHBox = () => {
        setETHAmount(BigNumber.from(0))
        let element: any = document.getElementById(SWAP_INPUT_ID)
        if (element) element.value = ""
    }

    const initLIQRBox = () => {
        setLIQRAmount(BigNumber.from(0))
        let element: any = document.getElementById(SWAP_OUTPUT_ID)
        if (element) element.value = ""
    }

    const setETHBoxValue = (val: BigNumber) => {
        let element: any = document.getElementById(SWAP_INPUT_ID)
        if (element) element.value = formatUnits(val, ethToken?.decimals)
    }

    const setLIQRBoxValue = (val: BigNumber) => {
        let element: any = document.getElementById(SWAP_OUTPUT_ID)
        if (element) element.value = formatUnits(val, liqrToken?.decimals)
    }

    const onInputChange = (val: string) => {
        let amount = getBigNumberFromInputString(val, ethToken?.decimals)
        if (!amount.eq(ethAmount)) {
            initLIQRBox()
        }
        setETHAmount(amount)
    }

    useEffect(() => {
        if (writeEstimatingOut_flag && queueEstimatingData.res) {
            setWriteEstimatingOut_flag(false)
            const res = queueEstimatingData.res
            const preInput = queueEstimatingData.preInAmount
            setQueueEstimatingData({ res: undefined, preInAmount: BigNumber.from(0) })
            if (res) {
                let val: BigNumber = res
                if (ethAmount.eq(preInput)) {
                    setLIQRAmount(val)
                    setLIQRBoxValue(val)
                    setEstimatedAmountOut(val)
                }
            } else {
                initLIQRBox()
                setEstimatedAmountOut(BigNumber.from(0))
            }
        }
    }, [writeEstimatingOut_flag, queueEstimatingData])

    const callEstimatedOutAmountCallback = async () => {
        try {
            let res
            let preInput = ethAmount

            res = await getAmountOut(ethAmount)
            res = getFixedAmount(res, LIQRInfo ? LIQRInfo.decimals : 18)
            setQueueEstimatingData({ res: res, preInAmount: preInput })
            setWriteEstimatingOut_flag(true)
        } catch (error) {
            initLIQRBox()
        }
    }

    const onETHMax = () => {
        let maxAbleBal = ethBalance
        if (isWrappedEther(selectedChainId as number, ethToken?.address)) {
            const SubBNBOnMax: BigNumber = parseUnits(SubBNBAmountOnMax, ethToken?.decimals)
            maxAbleBal = ethBalance.gte(SubBNBOnMax) ? ethBalance.sub(SubBNBOnMax) : BigNumber.from(0)
        }
        maxAbleBal = getFixedAmount(maxAbleBal, ethToken?.decimals)
        setETHAmount(maxAbleBal)
        setETHBoxValue(maxAbleBal)
    }

    const setSwapSuccess = (hash: string) => {
        initETHBox()
        initLIQRBox()
        setHash(hash)
        callETHBalanceCallback()
        callLIQRBalanceCallback()
    }

    const onSwap = async () => {
        setIsProcessing(true)
        try {
            await buyWithReferral(ethAmount, getSlippageExactFor()).then(async (res: any) => {
                if (res.status === 1) {
                    snackbar.snackbar.show("Swapped successfully!", "success")
                    setSwapSuccess(res.hash)
                    updateUserLIQRStakedStats()
                    updateUserLIQRLPStakedStats()
                    updateLIQR_PriceInUSD()
                    updateLIQRLP_PriceInUSD()
                } else {
                    snackbar.snackbar.show(`Transaction reverted! Tx:${res.hash}`, "error")
                }
            }).catch(error => {
                console.log(error)
                let err: any = error
                snackbar.snackbar.show(decodeTxErrorMessage(err), "error")
            })
        } catch (error) {
            console.log(error)
        }
        setIsProcessing(false)
    }

    const onChangeSlippageExactFor = (event: Event, newValue: number | number[]) => {
        setSlippageExactFor(Number(newValue))
    }

    const gotoDashboard = () => {
        navigate("/dashboard")
    }

    const checkReferralCode = async (code: string) => {
        try {
            const referralSystemContract: Contract = getContract(ReferralSystem_CAs[selectedChainId], REFERRALSYSTEM_ABI, RpcProviders[selectedChainId], undefined)
            const res = await referralSystemContract.userByReferralCode(code)
            if (res === AddressZero) {
                gotoDashboard()
            } else if (isAddress(res)) {
                setReferrerInfo({ referralCode: code, referrer: res })
            }
        } catch (err) {
            gotoDashboard()
        }
    }

    useEffect(() => {
        checkReferralCode(code)
    }, [code])

    return (
        <div className="w-full">
            <div className="w-full flex-1 fit-full-h mt-6">
                <div className='w-full flex justify-center items-center flex-1 fit-full-h'>
                    <div className='w-full max-w-[640px] rounded-2xl bg-[#101215] p-4 flex flex-col gap-2 mt-2'>
                        <div className='w-full flex gap-6 justify-center my-2 items-center'>
                            <div className='flex justify-center items-center rounded-2xl bg-app-content box-border'>
                                {ethToken && <div className='flex gap-2 py-2 px-3 justify-left items-center'>
                                    <div className="flex items-center justify-center w-6 h-6">
                                        <img src={ethToken?.logoURI} width="22" height="22" />
                                    </div>
                                    <div className='uppercase text-white text-[13px] sm:text-[14px] leading-[1.1] whitespace-nowrap'>{ethToken?.symbol}</div>
                                </div>}
                                <div className='text-[30px]'>
                                    {`->`}
                                </div>
                                {liqrToken && <div className='flex gap-2 py-2 px-3 justify-left items-center'>
                                    <div className="flex items-center justify-center w-6 h-6">
                                        <img src={liqrToken?.logoURI} width="22" height="22" />
                                    </div>
                                    <div className='uppercase text-white text-[13px] sm:text-[14px] leading-[1.1] whitespace-nowrap'>{liqrToken?.symbol}</div>
                                </div>}
                            </div>
                            <div className='text-[24px]'>
                                Buy
                            </div>
                        </div>
                        <LPQtyInput
                            id={SWAP_INPUT_ID}
                            name={ethToken ? ethToken?.symbol : undefined}
                            decimals={ethToken ? ethToken?.decimals : 18}
                            isAvailableMaxBtn={!isLoadingETHBalance && ethBalance.gt(0)}
                            balance={isLoadingETHBalance ? "--" : formatEther(ethBalance, ethToken?.decimals, getFixedDecimals(Number(formatUnits(ethBalance, ethToken?.decimals)), 3), true)}
                            onChange={(val: any) => onInputChange(val)}
                            logoURI={ethToken?.logoURI}
                            onOpenSelectModal={() => { }}
                            onMax={onETHMax} />
                        <div className="my-2"></div>
                        <LIQRQtyOutput
                            id={SWAP_OUTPUT_ID}
                            name={liqrToken ? liqrToken?.symbol : undefined}
                            decimals={liqrToken ? liqrToken?.decimals : 18}
                            isAvailableMaxBtn={!isLoadingLIQRBalance && liqrBalance.gt(0)}
                            balance={isLoadingLIQRBalance ? "--" : formatEther(liqrBalance, liqrToken?.decimals, getFixedDecimals(Number(formatUnits(liqrBalance, liqrToken?.decimals)), 3), true)}
                            logoURI={liqrToken?.logoURI}
                            onOpenSelectModal={() => { }} />
                        <SlippageBox
                            slippageExactFor={slippageExactFor}
                            setSlippageExactFor={setSlippageExactFor}
                            onChangeSlippageExactFor={onChangeSlippageExactFor}
                        />
                        <div className='text-white text-[14px] flex justify-between mx-1'>
                            <div>Minimum Received</div>
                            <div className='text-right'>
                                {minReceived.eq(0) ? '--' : `${formatEther_Optimized(minReceived, liqrToken?.decimals, 3, true)} ${liqrToken?.symbol}`}
                            </div>
                        </div>
                        {ethToken && <div className='mt-1 text-white text-[14px] flex justify-between mx-1 leading-[1.1]'>
                            <div>Native Coin Balance</div>
                            <div className='text-right'>{`${formatEther_Optimized(ethBalance, ethToken.decimals, 4, false)} ${getNativeSymbol(selectedChainId as number)}`}</div>
                        </div>}
                        {(ethAmount.gt(0) && liqrAmount.gt(0)) && account && (<div className='mt-1 text-white text-[14px] flex justify-between mx-1 leading-[1.1]'>
                            <div>Estimated Gas</div>
                            <div className='text-right'>{`${estimatedGas} ${getNativeSymbol(selectedChainId as number)}`}</div>
                        </div>)}
                        {ethAmount.gt(ethBalance) && account && <div className='mt-1 text text-[#ffff77] text-[14px] leading-[1.1]'>{`Insufficient balance of ${ethToken?.symbol}`}</div>}
                        <div className='flex gap-4 mt-2'>
                            {account ? <LoadingButton
                                variant="contained"
                                sx={{ width: "100%", borderRadius: "9999px", height: '45px' }}
                                loading={isProcessing}
                                loadingPosition="start"
                                onClick={onSwap}
                                disabled={!account || ethAmount.lte(0) || ethBalance.lt(ethAmount)}
                            >
                                {isProcessing ? 'Buying ...' : `Buy ${LIQRInfo ? LIQRInfo.symbol : "LIQR"}`}
                            </LoadingButton>
                                :
                                <ConnectButton />
                            }
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}